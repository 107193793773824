export default function Conteudo() {
  return (
    <>
      <h2>Engenheiro Mario Bhering: um artista</h2>
      <p>
        Tudo se move; em breve instante, se transforma. A roda do tempo vai
        moendo os grãos da vida. Como captar e fixar o instante fugidio, em que
        os elementos em concerto se conectam e induzem ao entendimento de que
        nada é estável, nada na natureza é definitivo? No aparente silêncio e na
        quietude desse lugar mínimo do universo que habitamos, cabe à arte
        estabelecer o fluxo da vida, alterando sua rota, para que nosso olhar
        possa ampliar a percepção do sentido de viver.
        <br />
        <br />
        Mario Bhering aceita o desafio. Como engenheiro, instalou poderosas
        turbinas, estancou as águas, depois as fez correr com força selvagem
        para produzir energia e mover a vida. Como artista, propõe captar outras
        águas, capturar os ventos, acender as luzes do sol e fazer ascender ao
        espaço mágico da folha branca os seus mistérios. E tudo se estabelece
        como memória do improvável que se faz real.
        <br />
        <br />
        <b>Márcio Sampaio</b>
      </p>
    </>
  );
}
