import Template from "paginas/Template";
import "estilo/paginas/template.css";
import { expedienteLogos } from "dados/paginas_dados_desktop";

export default function Expediente() {
  return (
    <>
      <Template>
        <div className="container_exp">
          <div className="expediente">
            <div className="titulo">
              <h3>
                CENTRO DA MEMÓRIA DA ELETRICIDADE NO BRASIL - MEMÓRIA DA
                ELETRICIDADE
              </h3>
            </div>

            <div className="bloco_baixo">
              <ul className="equipe">
                <li>
                  <b>Equipe:</b>
                </li>
                <li>Augusto Rodrigues | Presidente</li>
                <li>Alessandra Souto Maior</li>
                <li>Amanda Carvalho</li>
                <li>Andréia Silva</li>
                <li>Arthur Baptista</li>
                <li>Bruna Martoni</li>
                <li>Claudia Trigueiro</li>
                <li>Danielle Câmara</li>
                <li>Lamark Morais</li>
                <li>Leila Guimarães</li>
                <li>Lívia Santos</li>
                <li>Lorena Cordeiro</li>
                <li>Manuela Pereira</li>
                <li>Maria Araújo</li>
                <li>Marina Teixeira</li>
                <li>Paulo Brandi Cachapuz</li>
                <li>Priscila Moita</li>
                <li>Stephanie Costa</li>
                <li>Vanessa Baranda</li>
              </ul>
              <ul className="outros">
                <ul className="producao">
                  <li>
                    <b>Produção</b> | Centro da Memória da Eletricidade no
                    Brasil
                  </li>
                  <li>
                    <b>Projeto e Edição</b> | Alexandre Falcão
                  </li>
                  <li>
                    <b>Curadoria</b> | Márcio Sampaio
                  </li>
                  <li>
                    <b>Fotografia</b> | Marcus Knoedt
                  </li>
                  <li>
                    <b>Pesquisa Iconográfica</b> | Alexandre Falcão e Vanessa
                    Baranda
                  </li>
                  <li>
                    <b>Projeto Gráfico</b> | Mary Paz Guillén
                  </li>
                  <li>
                    <b>Assistente de produção</b> | Juliana Gouvêa
                  </li>
                  <li>
                    <b>Vídeos</b> | Arthur Baptista e Lamark Morais
                  </li>
                  <li>
                    <b>Desenvolvimento web</b> | Comunik16
                  </li>
                  <li>
                    <b>Acervo</b> | Família Bhering, José Luiz Alquéres e
                    Memória da Eletricidade
                  </li>
                </ul>
                <ul className="esforcos">
                  Foram realizados todos os esforços para obter as autorizações
                  para a reprodução das imagens utilizadas nesta exposição e
                  também para identificá-las corretamente. Em caso de omissão, o
                  editor compromete-se a consignar os créditos necessários e a
                  reservar os direitos a seus titulares.
                </ul>
                <div className="logos">
                  <div className="logo_1">
                    <div className="apoio">Apoio</div>
                    <div className="logos_apoio">
                      <img
                        src={expedienteLogos[0].src}
                        alt={expedienteLogos[0].alt}
                      />
                      <img
                        src={expedienteLogos[1].src}
                        alt={expedienteLogos[1].alt}
                      />
                    </div>
                  </div>
                  <div className="logo_2">
                    <div className="patrocinio">Patrocínio</div>
                    <div className="logos_patrocinio">
                      <img
                        src={expedienteLogos[2].src}
                        alt={expedienteLogos[2].alt}
                      />
                      <img
                        src={expedienteLogos[3].src}
                        alt={expedienteLogos[3].alt}
                      />
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </Template>
    </>
  );
}
