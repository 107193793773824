const containerVari = {
  iniciar: {
    opacity: 0,
    scale: 0,
    transition: {
      when: "afterChildren",
    },
  },
  animar: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.05,
      staggerChildren: 0.05,
      duration: 0.01,
      when: "beforeChildren",
    },
  },
  sair: {
    opacity: 0,
  },
};

export default containerVari;

export const home1mob = [
  // {
  //   id: 1,
  //   x: 0,
  //   y: 0,
  //   opacA: 0,
  //   opacZ: 1,
  //   src: "./img/home/0/fundo_mob.jpg",
  //   srcWebp: "./img/home/0/fundo_mob.webp",
  //   alt: "Textura clara de fundo",
  //   type: "jpeg",
  // },
  {
    id: 2,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_2.png",
    srcWebp: "./img/home/1/mob/camada_2.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_1.png",
    srcWebp: "./img/home/1/mob/camada_1.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 4,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/mob/assinatura.png",
    srcWebp: "./img/home/0/mob/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
  {
    id: 5,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_3.png",
    srcWebp: "./img/home/1/mob/camada_3.webp",
    alt: "Textura de rodapé",
    type: "png",
  },
];

export const home2mob = [
  // {
  //   id: 1,
  //   x: 0,
  //   y: 0,
  //   opacA: 0,
  //   opacZ: 1,
  //   src: "./img/home/0/fundo_mob.jpg",
  //   srcWebp: "./img/home/0/fundo_mob.webp",
  //   alt: "Textura clara de fundo",
  //   type: "jpeg",
  // },
  {
    id: 2,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/2/mob/memoria_1.png",
    srcWebp: "./img/home/2/mob/memoria_1.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/2/mob/memoria_2.png",
    srcWebp: "./img/home/2/mob/memoria_2.webp",
    alt: "Textura topo",
    type: "png",
  },
  // {
  //   id: 4,
  //   x: 100,
  //   y: 0,
  //   opacA: 0,
  //   opacZ: 1,
  //   src: "./img/home/2/mob/memoria_3.png",
  //   srcWebp: "./img/home/2/mob/memoria_3.webp",
  //   alt: "Textura de rodapé",
  //   type: "png",
  // },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/mob/assinatura.png",
    srcWebp: "./img/home/0/mob/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
  {
    id: 6,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_3.png",
    srcWebp: "./img/home/1/mob/camada_3.webp",
    alt: "Textura de rodapé",
    type: "png",
  },
];

export const home3mob = [
  // {
  //   id: 1,
  //   x: 0,
  //   y: 0,
  //   opacA: 0,
  //   opacZ: 1,
  //   src: "./img/home/0/fundo_mob.jpg",
  //   srcWebp: "./img/home/0/fundo_mob.webp",
  //   alt: "Textura clara de fundo",
  //   type: "jpeg",
  // },
  {
    id: 2,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/3/mob/mario_1.png",
    srcWebp: "./img/home/3/mob/mario_1.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/3/mob/mario_2.png",
    srcWebp: "./img/home/3/mob/mario_2.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 4,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/3/mob/mario_3.png",
    srcWebp: "./img/home/3/mob/mario_3.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/mob/assinatura.png",
    srcWebp: "./img/home/0/mob/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
  {
    id: 6,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_3.png",
    srcWebp: "./img/home/1/mob/camada_3.webp",
    alt: "Textura de rodapé",
    type: "png",
  },
];

export const home4mob = [
  // {
  //   id: 1,
  //   x: 0,
  //   y: 0,
  //   opacA: 0,
  //   opacZ: 1,
  //   src: "./img/home/0/fundo_mob.jpg",
  //   srcWebp: "./img/home/0/fundo_mob.webp",
  //   alt: "Textura clara de fundo",
  //   type: "jpeg",
  // },
  {
    id: 2,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/4/mob/principio_1.png",
    srcWebp: "./img/home/4/mob/principio_1.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/4/mob/principio_2.png",
    srcWebp: "./img/home/4/mob/principio_2.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 4,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/4/mob/principio_3.png",
    srcWebp: "./img/home/4/mob/principio_3.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/mob/assinatura.png",
    srcWebp: "./img/home/0/mob/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
  {
    id: 6,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_3.png",
    srcWebp: "./img/home/1/mob/camada_3.webp",
    alt: "Textura de rodapé",
    type: "png",
  },
];

export const home5mob = [
  // {
  //   id: 1,
  //   x: 0,
  //   y: 0,
  //   opacA: 0,
  //   opacZ: 1,
  //   src: "./img/home/0/fundo_mob.jpg",
  //   srcWebp: "./img/home/0/fundo_mob.webp",
  //   alt: "Textura clara de fundo",
  //   type: "jpeg",
  // },
  {
    id: 2,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/5/mob/inspiracao_1.png",
    srcWebp: "./img/home/5/mob/inspiracao_1.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/5/mob/inspiracao_2.png",
    srcWebp: "./img/home/5/mob/inspiracao_2.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 4,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/5/mob/inspiracao_3.png",
    srcWebp: "./img/home/5/mob/inspiracao_3.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/mob/assinatura.png",
    srcWebp: "./img/home/0/mob/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
  {
    id: 6,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_3.png",
    srcWebp: "./img/home/1/mob/camada_3.webp",
    alt: "Textura de rodapé",
    type: "png",
  },
];

export const home6mob = [
  // {
  //   id: 1,
  //   x: 0,
  //   y: 0,
  //   opacA: 0,
  //   opacZ: 1,
  //   src: "./img/home/0/fundo_mob.jpg",
  //   srcWebp: "./img/home/0/fundo_mob.webp",
  //   alt: "Textura clara de fundo",
  //   type: "jpeg",
  // },
  {
    id: 2,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/6/mob/livro_1.png",
    srcWebp: "./img/home/6/mob/livro_1.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/6/mob/livro_2.png",
    srcWebp: "./img/home/6/mob/livro_2.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 4,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/6/mob/livro_3.png",
    srcWebp: "./img/home/6/mob/livro_3.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/mob/assinatura.png",
    srcWebp: "./img/home/0/mob/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
  {
    id: 6,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_3.png",
    srcWebp: "./img/home/1/mob/camada_3.webp",
    alt: "Textura de rodapé",
    type: "png",
  },
];

export const home7mob = [
  // {
  //   id: 1,
  //   x: 0,
  //   y: 0,
  //   opacA: 0,
  //   opacZ: 1,
  //   src: "./img/home/0/fundo_mob.jpg",
  //   srcWebp: "./img/home/0/fundo_mob.webp",
  //   alt: "Textura clara de fundo",
  //   type: "jpeg",
  // },
  {
    id: 2,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/7/mob/video_1.png",
    srcWebp: "./img/home/7/mob/video_1.webp",
    alt: "Textura topo",
    type: "png",
    titulo: "Documentário (curto)",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/7/mob/video_2.png",
    srcWebp: "./img/home/7/mob/video_2.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 4,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/7/mob/video_3.png",
    srcWebp: "./img/home/7/mob/video_3.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/mob/assinatura.png",
    srcWebp: "./img/home/0/mob/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
  {
    id: 6,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_3.png",
    srcWebp: "./img/home/1/mob/camada_3.webp",
    alt: "Textura de rodapé",
    type: "png",
  },
];
