import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
// template e estilo
import Template from "paginas/Template";
import "estilo/paginas/template.css";
// componentes
import Modal from "componentes/Modal";
import GalNaturezaPlantas from "componentes/galerias/GalNaturezaPlantas";
import GalNaturezaLugares from "componentes/galerias/GalNaturezaLugares";
// dados desktop
import { naturezaDados, tempConteudoAnim } from "dados/paginas_dados_desktop";
// dados mobile
import { naturezadadosmob } from "dados/paginas_dados_mobile";

function Natureza({ children }) {
  const ehMobile = useMediaQuery({ query: "(max-width: 1025px)" });
  const [abrirModal, setAbrirModal] = useState(false);
  const [abrirModal1, setAbrirModal1] = useState(false);

  const btnFecharModal = () => {
    if (setAbrirModal(true)) {
      setAbrirModal((current) => !current);
    } else if (setAbrirModal(false)) {
      setAbrirModal((current) => current);
    } else if (setAbrirModal1(true)) {
      setAbrirModal1((current) => !current);
    } else if (setAbrirModal1(false)) {
      setAbrirModal1((current) => current);
    }
  };

  const lidarComModal = () => {
    if (setAbrirModal(true)) {
      setAbrirModal((current) => !current);
    } else {
      setAbrirModal((current) => current);
    }
  };

  const lidarComModal1 = () => {
    if (setAbrirModal1(true)) {
      setAbrirModal1((current) => !current);
    } else {
      setAbrirModal1((current) => current);
    }
  };

  return (
    <>
      {abrirModal && (
        <Modal btnFecharModal={btnFecharModal} className>
          <GalNaturezaLugares />
        </Modal>
      )}
      {abrirModal1 && (
        <Modal btnFecharModal={btnFecharModal}>
          <GalNaturezaPlantas />
        </Modal>
      )}
      <Template>
        {!ehMobile && (
          <div className="natureza_6 titulo_6">
            <h2 onClick={lidarComModal}>{naturezaDados[5].titulo}</h2>
          </div>
        )}
        {!ehMobile && (
          <div className="natureza_5 titulo_5">
            <h2 onClick={lidarComModal1}>{naturezaDados[4].titulo}</h2>
          </div>
        )}
        {ehMobile && (
          <AnimatePresence>
            <motion.div
              className="natureza_mob subcont_mob"
              variants={tempConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <div className="img_pag_mob" onClick={lidarComModal1}>
                <picture>
                  <source
                    srcSet={naturezadadosmob[2].srcWebp}
                    type="image/webp"
                  />
                  <source
                    srcSet={naturezadadosmob[2].src}
                    type={`image/${naturezadadosmob[2].type}`}
                  />
                  <img
                    src={naturezadadosmob[2].src}
                    alt={naturezadadosmob[2].alt}
                  />
                </picture>
                <div className="titulo_pag_mob">
                  <h2>Plantas</h2>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        )}
        {ehMobile && (
          <AnimatePresence>
            <motion.div
              className="natureza_mob subcont_mob"
              variants={tempConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <div className="img_pag_mob" onClick={lidarComModal}>
                <picture>
                  <source
                    srcSet={naturezadadosmob[3].srcWebp}
                    type="image/webp"
                  />
                  <source
                    srcSet={naturezadadosmob[3].src}
                    type={`image/${naturezadadosmob[3].type}`}
                  />
                  <img
                    src={naturezadadosmob[3].src}
                    alt={naturezadadosmob[3].alt}
                  />
                </picture>
                <div className="titulo_pag_mob">
                  <h2>
                    Lugares da
                    <br />
                    natureza
                  </h2>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        )}
      </Template>
    </>
  );
}

export default Natureza;
