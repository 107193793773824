import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
// template e estilo
import Template from "paginas/Template";
import "estilo/paginas/template.css";
// componente
import Modal from "componentes/Modal";
import GalOMarCotidiano from "componentes/galerias/GalOMarCotidiano";
import GalOMarMiudezas from "componentes/galerias/GalOMarMiudezas";
import GalOMarFascinio from "componentes/galerias/GalOMarFascinio";
// dados desktop
import { oMarDados, tempConteudoAnim } from "dados/paginas_dados_desktop";
// dados mobile
import { omardadosmob } from "dados/paginas_dados_mobile";

export default function OMar({ children }) {
  const ehMobile = useMediaQuery({ query: "(max-width: 1025px)" });
  const [abrirModal, setAbrirModal] = useState(false);
  const [abrirModal1, setAbrirModal1] = useState(false);
  const [abrirModal2, setAbrirModal2] = useState(false);

  const btnFecharModal = () => {
    if (setAbrirModal(true)) {
      setAbrirModal((current) => !current);
    } else if (setAbrirModal(false)) {
      setAbrirModal((current) => current);
    } else if (setAbrirModal1(true)) {
      setAbrirModal1((current) => !current);
    } else if (setAbrirModal1(false)) {
      setAbrirModal1((current) => current);
    } else if (setAbrirModal2(true)) {
      setAbrirModal2((current) => !current);
    } else if (setAbrirModal2(false)) {
      setAbrirModal2((current) => current);
    }
  };

  const lidarComModal = () => {
    if (setAbrirModal(true)) {
      setAbrirModal((current) => !current);
    } else {
      setAbrirModal((current) => current);
    }
  };

  const lidarComModal1 = () => {
    if (setAbrirModal1(true)) {
      setAbrirModal1((current) => !current);
    } else {
      setAbrirModal1((current) => current);
    }
  };

  const lidarComModal2 = () => {
    if (setAbrirModal2(true)) {
      setAbrirModal2((current) => !current);
    } else {
      setAbrirModal2((current) => current);
    }
  };

  return (
    <>
      {abrirModal && (
        <Modal btnFecharModal={btnFecharModal} className>
          <GalOMarFascinio />
        </Modal>
      )}
      {abrirModal1 && (
        <Modal btnFecharModal={btnFecharModal}>
          <GalOMarMiudezas />
        </Modal>
      )}
      {abrirModal2 && (
        <Modal btnFecharModal={btnFecharModal}>
          <GalOMarCotidiano />
        </Modal>
      )}
      <Template>
        {!ehMobile && (
          <div className="o_mar_4 titulo_4">
            <h2 onClick={lidarComModal}>{oMarDados[3].titulo}</h2>
          </div>
        )}
        {!ehMobile && (
          <div className="o_mar_5 titulo_5">
            <h2 onClick={lidarComModal1}>{oMarDados[4].titulo}</h2>
          </div>
        )}
        {!ehMobile && (
          <div className="o_mar_6 titulo_6">
            <h2 onClick={lidarComModal2}>{oMarDados[5].titulo}</h2>
          </div>
        )}
        {ehMobile && (
          <AnimatePresence>
            <motion.div
              className="omar_mob subcont_mob"
              variants={tempConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <div className="img_pag_mob" onClick={lidarComModal}>
                <picture>
                  <source srcSet={omardadosmob[2].srcWebp} type="image/webp" />
                  <source
                    srcSet={omardadosmob[2].src}
                    type={`image/${omardadosmob[2].type}`}
                  />
                  <img src={omardadosmob[2].src} alt={omardadosmob[2].alt} />
                </picture>
                <div className="titulo_pag_mob">
                  <h2>Fascínio pelo mar</h2>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        )}
        {ehMobile && (
          <AnimatePresence>
            <motion.div
              className="omar_mob subcont_mob"
              variants={tempConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <div className="img_pag_mob" onClick={lidarComModal1}>
                <picture>
                  <source srcSet={omardadosmob[3].srcWebp} type="image/webp" />
                  <source
                    srcSet={omardadosmob[3].src}
                    type={`image/${omardadosmob[3].type}`}
                  />
                  <img src={omardadosmob[3].src} alt={omardadosmob[2].alt} />
                </picture>
                <div className="titulo_pag_mob">
                  <h2>Miudezas</h2>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        )}
        {ehMobile && (
          <AnimatePresence>
            <motion.div
              className="omar_mob subcont_mob"
              variants={tempConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <div className="img_pag_mob" onClick={lidarComModal2}>
                <picture>
                  <source srcSet={omardadosmob[4].srcWebp} type="image/webp" />
                  <source
                    srcSet={omardadosmob[4].src}
                    type={`image/${omardadosmob[4].type}`}
                  />
                  <img src={omardadosmob[4].src} alt={omardadosmob[4].alt} />
                </picture>
                <div className="titulo_pag_mob">
                  <h2>O cotidiano à beira mar</h2>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        )}
      </Template>
    </>
  );
}
