import { useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import { useMediaQuery } from "react-responsive";
import { motion, AnimatePresence } from "framer-motion";
// componentes e estilo
import Modal from "componentes/Modal";
import "estilo/paginas/home.css";
// dados desktop
import containerVari, {
  homeConteudoAnim,
  home7 as camadasxy,
  home7videos,
} from "dados/home_dados_desktop";
// dados mobile
import { home7mob as camadasxymob } from "dados/home_dados_mobile";

export default function Home7() {
  const ehMobile = useMediaQuery({ query: "(max-width: 1025px)" });
  const topo_camada_mob = camadasxymob.slice(0, -1);
  const rodape_camada_mob = camadasxymob[camadasxymob.length - 1];
  const videoOpcoes: YouTubeProps["opts"] = {
    height: "300",
    width: "1600",
    playerVars: {
      controls: 2,
      rel: 0,
      showinfo: 0,
      allowfullscreen: 0,
      disablekb: 1,
      fs: 0,
      modestbranding: 1,
    },
  };
  const [abrirModal1, setAbrirModal1] = useState(false);
  const [abrirModal2, setAbrirModal2] = useState(false);

  const btnFecharModal = () => {
    if (setAbrirModal1(true)) {
      setAbrirModal1((current) => !current);
    } else if (setAbrirModal1(false)) {
      setAbrirModal1((current) => current);
    } else if (setAbrirModal2(true)) {
      setAbrirModal2((current) => !current);
    } else if (setAbrirModal2(false)) {
      setAbrirModal2((current) => current);
    }
  };

  const lidarComModal1 = () => {
    if (setAbrirModal1(true)) {
      setAbrirModal1((current) => !current);
    } else {
      setAbrirModal1((current) => current);
    }
  };

  const lidarComModal2 = () => {
    if (setAbrirModal2(true)) {
      setAbrirModal2((current) => !current);
    } else {
      setAbrirModal2((current) => current);
    }
  };
  return (
    <>
      {abrirModal1 && (
        <Modal btnFecharModal={btnFecharModal} className="modal_home7">
          <div className="modal_video_2">
            <YouTube videoId="7LPLp4dhJ8U" opts={videoOpcoes} />
          </div>
        </Modal>
      )}
      {abrirModal2 && (
        <Modal btnFecharModal={btnFecharModal} className="modal_home7">
          <div className="modal_video_2">
            <YouTube videoId="GxB3A21LdSw" opts={videoOpcoes} />
          </div>
        </Modal>
      )}
      {!ehMobile && (
        <AnimatePresence>
          <motion.section
            className="home"
            variants={containerVari}
            initial="iniciar"
            animate="animar"
          >
            {camadasxy.map((camadaxy, index) => {
              const camadaVari = {
                iniciar: {
                  x: `${camadaxy.x}vw`,
                  y: `${camadaxy.y}vh`,
                  opacity: camadaxy.opacA,
                },
                animar: {
                  x: 0,
                  y: 0,
                  opacity: camadaxy.opacZ,
                  transition: {
                    type: "Inertia",
                    duration: 1,
                  },
                },
              };
              return (
                <AnimatePresence key={camadaxy.id}>
                  <motion.div
                    className={`camada_${index + 1} camada`}
                    variants={camadaVari}
                  >
                    {camadaxy.src && camadaxy.srcWebp && (
                      <picture>
                        <source srcSet={camadaxy.srcWebp} type="image/webp" />
                        <source
                          srcSet={camadaxy.src}
                          type={`image/${camadaxy.type}`}
                        />
                        <img src={camadaxy.src} alt={camadaxy.alt} />
                      </picture>
                    )}
                  </motion.div>
                </AnimatePresence>
              );
            })}
          </motion.section>
        </AnimatePresence>
      )}
      {!ehMobile && (
        <AnimatePresence>
          <motion.div
            className="conteudo home7"
            variants={homeConteudoAnim}
            initial="iniciar"
            animate="animar"
            exit="sair"
          >
            {" "}
            <div className="container">
              <div className="titulo">
                <h2>Os depoimentos</h2>
              </div>
              <div className="video">
                {home7videos.map((video, index) => (
                  <div
                    className={`video_${index + 1}`}
                    onClick={index === 1 ? lidarComModal1 : lidarComModal2}
                    style={{ zIndex: 150 }}
                  >
                    <h4>{video.titulo}</h4>
                    <picture>
                      <source srcSet={video.src} type="image/webp" />
                      <source srcSet={video.src} type="image/jpeg" />
                      <img
                        src={video.src}
                        alt={video.alt}
                        style={{ boxShadow: "5px 5px 7.5px" }}
                      />
                    </picture>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      )}

      {ehMobile && (
        <main className="mobile">
          <AnimatePresence>
            <motion.header
              className="topo_mob"
              variants={containerVari}
              initial="iniciar"
              animate="animar"
            >
              {topo_camada_mob.map((camadaxy, index) => {
                const camadaVari = {
                  iniciar: {
                    x: `${camadaxy.x}vw`,
                    y: `${camadaxy.y}vh`,
                    opacity: camadaxy.opacA,
                  },
                  animar: {
                    x: 0,
                    y: 0,
                    opacity: camadaxy.opacZ,
                    transition: {
                      type: "Inertia",
                      duration: 1,
                    },
                  },
                };
                return (
                  <AnimatePresence key={camadaxy.id * Math.random()}>
                    <motion.div
                      className={`camada_mob_${index + 1} camada_mob`}
                      variants={camadaVari}
                      style={{ zIndex: 100 + index }}
                    >
                      {camadaxy.src && camadaxy.srcWebp && (
                        <picture>
                          <source srcSet={camadaxy.srcWebp} type="image/webp" />
                          <source
                            srcSet={camadaxy.src}
                            type={`image/${camadaxy.type}`}
                          />
                          <img src={camadaxy.src} alt={camadaxy.alt} />
                        </picture>
                      )}
                    </motion.div>
                  </AnimatePresence>
                );
              })}
            </motion.header>
          </AnimatePresence>
          <AnimatePresence>
            <motion.main
              className="conteudo_mob"
              variants={homeConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <div className="container">
                <div className="titulo">
                  <h2>Os depoimentos</h2>
                </div>
                <div className="video">
                  {home7videos.map((video, index) => (
                    <div
                      className={`video_${index + 1}`}
                      onClick={index === 1 ? lidarComModal1 : lidarComModal2}
                      style={{ zIndex: 150 }}
                    >
                      <picture>
                        <source srcSet={video.src} type="image/webp" />
                        <source srcSet={video.src} type="image/jpeg" />
                        <img src={video.src} alt={video.alt} />
                      </picture>
                      <h4>{video.titulo}</h4>
                    </div>
                  ))}
                </div>
              </div>
            </motion.main>
          </AnimatePresence>
          <AnimatePresence>
            <motion.footer
              className="rodape_mob"
              variants={homeConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <picture>
                <source srcSet={rodape_camada_mob.srcWebp} type="image/webp" />
                <source
                  srcSet={rodape_camada_mob.src}
                  type={`image/${rodape_camada_mob.type}`}
                />
                <img src={rodape_camada_mob.src} alt={rodape_camada_mob.alt} />
              </picture>
            </motion.footer>
          </AnimatePresence>
        </main>
      )}
    </>
  );
}
