import { useLocation, matchPath } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useMediaQuery } from "react-responsive";
// dados
import containerVari, { tempConteudoAnim } from "dados/paginas_dados_desktop";

export default function Template({ children }) {
  const ehMobile = useMediaQuery({ query: "(max-width: 1025px)" });

  const urlRoute = useLocation();
  // eslint-disable-next-line no-unused-vars
  const { pathname } = urlRoute;

  let matchNatureza = !!matchPath(urlRoute.pathname, "/Natureza");
  let matchMarioPorMario = !!matchPath(urlRoute.pathname, "/MarioPorMario");
  let matchExpediente = !!matchPath(urlRoute.pathname, "/Expediente");
  let matchOMar = !!matchPath(urlRoute.pathname, "/OMar");
  let matchPessoas = !!matchPath(urlRoute.pathname, "/Pessoas");
  let matchUsinas = !!matchPath(urlRoute.pathname, "/Usinas");

  let camadasXY = {};
  let camadasxymob = {};

  if (matchNatureza) {
    camadasXY = require("dados/paginas_dados_desktop").naturezaDados;
    camadasxymob = require("dados/paginas_dados_mobile").naturezadadosmob;
  } else if (matchOMar) {
    camadasXY = require("dados/paginas_dados_desktop").oMarDados;
    camadasxymob = require("dados/paginas_dados_mobile").omardadosmob;
  } else if (matchPessoas) {
    camadasXY = require("dados/paginas_dados_desktop").pessoasDados;
    camadasxymob = require("dados/paginas_dados_mobile").pessoasdadosmob;
  } else if (matchUsinas) {
    camadasXY = require("dados/paginas_dados_desktop").usinasDados;
    camadasxymob = require("dados/paginas_dados_mobile").usinasdadosmob;
  } else if (matchMarioPorMario) {
    camadasXY = require("dados/paginas_dados_desktop").marioDados;
    camadasxymob = require("dados/paginas_dados_mobile").mariodadosmob;
  } else if (matchExpediente) {
    camadasXY = require("dados/paginas_dados_desktop").expedienteDados;
    camadasxymob = require("dados/paginas_dados_mobile").naturezadadosmob;
  }

  const topo_camada_mob = camadasxymob.slice(0, 2);
  const rodape_camada_mob = camadasxymob[camadasxymob.length - 1];

  return (
    <>
      {!ehMobile && (
        <AnimatePresence>
          <motion.section
            className="template"
            variants={containerVari}
            initial="iniciar"
            animate="animar"
          >
            {camadasXY.map((camadaXY, index) => {
              const camadaVari = {
                iniciar: {
                  x: `${camadaXY.x}vw`,
                  y: `${camadaXY.y}vh`,
                  opacity: camadaXY.opacA,
                },
                animar: {
                  x: 0,
                  y: 0,
                  opacity: camadaXY.opacZ,
                  transition: {
                    type: "Inertia",
                    duration: 1,
                  },
                },
              };
              return (
                <AnimatePresence key={camadaXY.id * Math.random()}>
                  <motion.div
                    className={`camada camada_${index + 1} ${camadaXY.classe}_${
                      index + 1
                    }`}
                    variants={camadaVari}
                  >
                    {camadaXY.src && camadaXY.srcWebp && (
                      <picture>
                        <source srcSet={camadaXY.srcWebp} type="image/webp" />
                        <source
                          srcSet={camadaXY.src}
                          type={`image/${camadaXY.type}`}
                        />
                        <img src={camadaXY.src} alt={camadaXY.alt} />
                      </picture>
                    )}
                  </motion.div>
                </AnimatePresence>
              );
            })}
          </motion.section>
        </AnimatePresence>
      )}
      {!ehMobile && (
        <AnimatePresence>
          <motion.div
            className="conteudo"
            variants={tempConteudoAnim}
            initial="iniciar"
            animate="animar"
            exit="sair"
          >
            {children}
          </motion.div>
        </AnimatePresence>
      )}
      {ehMobile && (
        <main className="mobile">
          <AnimatePresence>
            <motion.header
              className="topo_mob"
              variants={containerVari}
              initial="iniciar"
              animate="animar"
            >
              {topo_camada_mob.map((camadaxy, index) => {
                const camadaVari = {
                  iniciar: {
                    x: `${camadaxy.x}vw`,
                    y: `${camadaxy.y}vh`,
                    opacity: camadaxy.opacA,
                  },
                  animar: {
                    x: 0,
                    y: 0,
                    opacity: camadaxy.opacZ,
                    transition: {
                      type: "Inertia",
                      duration: 1,
                    },
                  },
                };
                return (
                  <AnimatePresence key={camadaxy.id * Math.random()}>
                    <motion.div
                      className={`camada_mob_${index + 1} camada_mob`}
                      variants={camadaVari}
                      style={{ zIndex: 100 + index }}
                    >
                      {camadaxy.src && camadaxy.srcWebp && (
                        <picture>
                          <source srcSet={camadaxy.srcWebp} type="image/webp" />
                          <source
                            srcSet={camadaxy.src}
                            type={`image/${camadaxy.type}`}
                          />
                          <img src={camadaxy.src} alt={camadaxy.alt} />
                        </picture>
                      )}
                    </motion.div>
                  </AnimatePresence>
                );
              })}
            </motion.header>
          </AnimatePresence>
          <AnimatePresence>
            <motion.main
              className="conteudo_pag_mob"
              variants={tempConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <div>{children}</div>
            </motion.main>
          </AnimatePresence>
          <AnimatePresence>
            <motion.footer
              className="rodape_mob"
              variants={tempConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <picture>
                <source srcSet={rodape_camada_mob.srcWebp} type="image/webp" />
                <source
                  srcSet={rodape_camada_mob.src}
                  type={`image/${rodape_camada_mob.type}`}
                />
                <img src={rodape_camada_mob.src} alt={rodape_camada_mob.alt} />
              </picture>
            </motion.footer>
          </AnimatePresence>
        </main>
      )}
    </>
  );
}
