export default function Conteudo() {
  return (
    <>
      <h2>A memória e a arte</h2>
      <p>
        “O respeito pelo conhecimento é uma característica das civilizações mais
        interessantes do mundo. Meu pai, Mario Bhering, acreditava, como muitos
        de nós, que a memória das empresas conta a história do país, das
        revoluções tecnológicas, dos tempos de crise, dos acordos diplomáticos.
        A Memória, assim como as bibliotecas e outros centros de educação
        permanente, são entidades de apoio às pesquisas, mas também fórum de
        reflexão e discussão. Para mim, é uma alegria ver a Memória permanecer
        discutindo tendências e apontando caminhos para o setor elétrico.
        Continuar a ser um fórum cada vez mais rico. Os cadernos da Memória
        estão hoje entre as melhores publicações nacionais de reflexão sobre
        informação, cultura e saber.
        <br />
        <br />
        As ações de celebração do centenário de meu pai resgatam sua essência
        tendo por base o seu perfil humanista e artístico, um pensador e
        executor de ideias em palavras e imagens. A exposição virtual de suas
        aquarelas, como parte das ações comemorativas, revela visões e
        impressões muito claras e diversificadas da harmonia do ser humano com a
        natureza, de contemplação da luz, da pureza do desenho e do seu olhar
        refinado.”
        <br />
        <br />
        <b>Cecília Bhering</b>
      </p>
    </>
  );
}
