import { useState } from "react";
import { NavLink, useNavigate, useLocation, matchPath } from "react-router-dom";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
// componente e estilo
import Tooltip from "componentes/Tooltip";
import "estilo/componentes/navbar.css";

export default function Navbar({ telaCheiaRef }) {
  const ehMobile = useMediaQuery({ query: "(max-width: 1025px)" });
  const sele = "rgba(37, 150, 190, 0.5)";

  let pagAtiva = "pag_ativa";

  let icoHome = [
    {
      src: "./img/navbar/home.png",
      alt: "Botão home",
      link: "/Home/1",
      Tooltip: "Home",
    },
  ];

  let imgNavbar = [
    {
      src: "./img/navbar/01_circulo.png",
      alt: "Botão Mario por Mario",
      id: 1,
      link: "/MarioPorMario",
      Tooltip: "Mario por Mario",
    },
    {
      src: "./img/navbar/02_circulo.png",
      alt: "Botão Natureza",
      id: 2,
      link: "/Natureza",
      Tooltip: "Natureza",
    },
    {
      src: "./img/navbar/03_circulo.png",
      alt: "Botão O mar",
      id: 3,
      link: "/OMar",
      Tooltip: "O mar",
    },
    {
      src: "./img/navbar/04_circulo.png",
      alt: "Botão Usinas",
      id: 5,
      link: "/Usinas",
      Tooltip: "Usinas",
    },
    {
      src: "./img/navbar/05_circulo.png",
      alt: "Botão Pessoas",
      id: 6,
      link: "/Pessoas",
      Tooltip: "Pessoas",
    },
    {
      src: "./img/navbar/00_circulo.png",
      alt: "Botão Expediente",
      id: 7,
      link: "/Expediente",
      Tooltip: "Expediente",
    },
  ];

  let imgSeta = [
    {
      src: "./img/navbar/seta-esquerda.png",
      alt: "Seta esquerda",
    },
    {
      src: "./img/navbar/seta-direita.png",
      alt: "Seta direita",
    },
  ];

  let icoTelaCheia = [
    {
      src: "./img/navbar/tc_abre.png",
      alt: "Botão de abrir o modo de tela cheia",
    },
    {
      src: "./img/navbar/tc_fecha.png",
      alt: "Botão de sair a tela cheia",
    },
  ];

  const navCirculoVari = {
    iniciar: {
      scale: 0,
    },
    animar: {
      scale: 1,
      rotate: 180,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
        duration: 1,
      },
      staggerChildren: 0.5,
    },
    hover: {
      // scale: 0.5,
      rotate: 90,
    },
    tap: {
      // scale: 0.8,
      rotate: -90,
      borderRadius: "100%",
    },
  };

  const navHomeVari = {
    iniciar: {
      scale: 0,
      opacity: 0,
    },
    animar: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
      },
      staggerChildren: 0.5,
    },
    hover: {
      // scale: 0.5,
      rotate: -10,
    },
    tap: {
      rotate: -30,
      scale: 1,
      transition: {
        ease: "easeOut",
        duration: 1,
      },
    },
  };

  const urlRoute = useLocation();
  let ultimoCarac = urlRoute.pathname.charAt(urlRoute.pathname.length - 1);

  const { pathname } = urlRoute;
  const splitLocation = pathname.split("/");

  switch (splitLocation) {
    case "1": //primeira condição
      break;
    case 2: //segunda condição
      break;
    default:
  }

  let proxLink = () => {
    if (ultimoCarac < 7) {
      ultimoCarac++;
    }
    navigate(`/Home/${ultimoCarac}`);
  };

  // const [pulsar, setPulsar] = useState(null);

  // let pulsarFunc = () => {
  //   if (ultimoCarac < 7) {
  //     setPulsar("pulsar");
  //   }
  // };

  let antLink = () => {
    if (ultimoCarac <= 7 && ultimoCarac > 1) {
      ultimoCarac--;
    }
    navigate(`/Home/${ultimoCarac}`);
    // posAtiva();
  };

  let navigate = useNavigate();

  const matchNatureza = !!matchPath(urlRoute.pathname, "/Natureza");
  const matchOMar = !!matchPath(urlRoute.pathname, "/OMar");
  const matchPessoas = !!matchPath(urlRoute.pathname, "/Pessoas");
  const matchUsinas = !!matchPath(urlRoute.pathname, "/Usinas");
  const matchExpediente = !!matchPath(urlRoute.pathname, "/Expediente");
  const matchMario = !!matchPath(urlRoute.pathname, "/MarioPorMario");
  const matchHome1 = !!matchPath(urlRoute.pathname, "/Home/1");
  const matchHome2 = !!matchPath(urlRoute.pathname, "/Home/2");
  const matchHome3 = !!matchPath(urlRoute.pathname, "/Home/3");
  const matchHome4 = !!matchPath(urlRoute.pathname, "/Home/4");
  const matchHome5 = !!matchPath(urlRoute.pathname, "/Home/5");
  const matchHome6 = !!matchPath(urlRoute.pathname, "/Home/6");
  const matchHome7 = !!matchPath(urlRoute.pathname, "/Home/7");

  const [TelaCheia, setTelaCheia] = useState(false);

  const abrirTelaCheia = () => {
    setTelaCheia(true);
    document.documentElement.requestFullscreen();
  };

  const fecharTelaCheia = () => {
    setTelaCheia(false);
    document.exitFullscreen();
  };

  return (
    <nav>
      <motion.ul
        className="menu"
        initial={{ x: "-50vw" }}
        animate={{ x: 0 }}
        transition={{ delay: 0.25, type: "spring", stiffness: 120 }}
      >
        <ul className="bloco_1">
          <ul className="btn_circulos">
            <motion.li
              variants={navHomeVari}
              initial="iniciar"
              animate="animar"
              whileHover="hover"
              whileTap="tap"
            >
              <NavLink
                to={icoHome[0].link}
                className={({ isActive }) =>
                  isActive ? "pagativa" : undefined
                }
              >
                <img
                  src={icoHome[0].src}
                  alt="Botão home"
                  className="btn_home"
                  style={{
                    transform:
                      matchHome1 ||
                      matchHome2 ||
                      matchHome3 ||
                      matchHome4 ||
                      matchHome5 ||
                      matchHome6 ||
                      matchHome7
                        ? "scale(0.5)"
                        : undefined,
                  }}
                />
              </NavLink>
            </motion.li>
            {imgNavbar.map((icoNavItem, index) => (
              <Tooltip content={icoNavItem.Tooltip} key={icoNavItem.id}>
                <motion.li
                  variants={navCirculoVari}
                  initial="iniciar"
                  animate="animar"
                  whileHover="hover"
                  whileTap="tap"
                >
                  <NavLink
                    to={icoNavItem.link}
                    className={({ isActive }) =>
                      isActive ? pagAtiva : undefined
                    }
                  >
                    <img src={icoNavItem.src} alt={icoNavItem.titulo} />
                  </NavLink>
                </motion.li>
              </Tooltip>
            ))}
          </ul>
        </ul>
        {!ehMobile && (
          <ul
            className="bloco_2"
            style={{
              justifyContent:
                matchOMar ||
                matchPessoas ||
                matchUsinas ||
                matchMario ||
                matchNatureza ||
                matchExpediente
                  ? "flex-end"
                  : undefined,
            }}
          >
            {!matchOMar &&
              !matchExpediente &&
              !matchPessoas &&
              !matchUsinas &&
              !matchMario &&
              !matchNatureza && (
                <div>
                  <ul className="btn_seta">
                    <li onClick={antLink}>
                      <img
                        src={imgSeta[0].src}
                        alt={imgSeta[0].src}
                        style={{
                          opacity: matchHome1 ? 0.3 : "",
                        }}
                      />
                    </li>
                    <li onClick={proxLink}>
                      <img
                        src={imgSeta[1].src}
                        alt={imgSeta[1].src}
                        // className={({ pulsarFunc }) =>
                        //   pulsarFunc ? pulsar : undefined
                        // }
                        style={{
                          opacity: matchHome7 ? 0.3 : "",
                        }}
                      />
                    </li>
                  </ul>
                  <div className="posicao_home">
                    <span
                      className="bola"
                      style={{
                        background: matchHome1 ? sele : "",
                      }}
                    ></span>
                    <span
                      className="bola"
                      style={{
                        background: matchHome2 ? sele : "",
                      }}
                    ></span>
                    <span
                      className="bola"
                      style={{
                        background: matchHome3 ? sele : "",
                      }}
                    ></span>
                    <span
                      className="bola"
                      style={{
                        background: matchHome4 ? sele : "",
                      }}
                    ></span>
                    <span
                      className="bola"
                      style={{
                        background: matchHome5 ? sele : "",
                      }}
                    ></span>
                    <span
                      className="bola"
                      style={{
                        background: matchHome6 ? sele : "",
                      }}
                    ></span>
                    <span
                      className="bola"
                      style={{
                        background: matchHome7 ? sele : "",
                      }}
                    ></span>
                  </div>
                </div>
              )}

            <ul className="btn_telha_cheia">
              {!TelaCheia && (
                <li onClick={abrirTelaCheia}>
                  <img src={icoTelaCheia[0].src} alt={icoTelaCheia[0].src} />
                </li>
              )}
              {TelaCheia && (
                <li onClick={fecharTelaCheia}>
                  <img src={icoTelaCheia[1].src} alt={icoTelaCheia[1].src} />
                </li>
              )}
            </ul>
          </ul>
        )}
      </motion.ul>
      {ehMobile &&
        !matchOMar &&
        !matchExpediente &&
        !matchPessoas &&
        !matchUsinas &&
        !matchMario &&
        !matchNatureza && (
          <div className="seta_home_mob">
            <ul className="btn_seta">
              <li onClick={antLink}>
                <img
                  src={imgSeta[0].src}
                  alt={imgSeta[0].src}
                  style={{
                    opacity: matchHome1 ? 0.3 : "",
                  }}
                />
              </li>
              <li onClick={proxLink}>
                <img
                  src={imgSeta[1].src}
                  alt={imgSeta[1].src}
                  style={{
                    opacity: matchHome7 ? 0.3 : "",
                  }}
                  // className={({ pulsarFunc }) =>
                  //   pulsarFunc ? pulsar : undefined
                  // }
                />
              </li>
            </ul>
          </div>
        )}
    </nav>
  );
}
