import { motion, AnimatePresence } from "framer-motion";
import "estilo/componentes/modal.css";

const ModalContainer = {
  escondido: { opacity: 0 },
  visivel: { opacity: 1 },
};

export default function Modal({ children, lidarComModal, btnFecharModal }) {
  return (
    <AnimatePresence mode="wait">
      <motion.div
        className="modal_container"
        variants={ModalContainer}
        initial="escondido"
        animate="visivel"
      >
        <motion.div className="modal">{children}</motion.div>
        <button onClick={btnFecharModal} className="btn_fechar">
          X
        </button>
      </motion.div>
    </AnimatePresence>
  );
}
