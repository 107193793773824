export default function Livro() {
  return (
    <>
      <iframe
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
        className="responsive-iframe"
        src="https://heyzine.com/flip-book/9626b65c58.html"
        margin="0"
        width="94%"
        height="88%"
        title="sdds"
        frameBorder={0}
      />
    </>
  );
}
