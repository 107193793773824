import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
// template e estilo
import Template from "paginas/Template";
import "estilo/paginas/template.css";
// componentes
import Modal from "componentes/Modal";
import GalPessoas from "componentes/galerias/GalPessoas";
// dados desktop
import { pessoasDados, tempConteudoAnim } from "dados/paginas_dados_desktop";
// dados mobile
import { pessoasdadosmob } from "dados/paginas_dados_mobile";

export default function Pessoas({ children }) {
  const ehDesktop = useMediaQuery({ query: "(min-width: 1026px)" });
  const ehMobile = useMediaQuery({ query: "(max-width: 1025px)" });
  const [abrirModal, setAbrirModal] = useState(false);

  const btnFecharModal = () => {
    if (setAbrirModal(true)) {
      setAbrirModal((current) => !current);
    } else if (setAbrirModal(false)) {
      setAbrirModal((current) => current);
    }
  };

  const lidarComModal = () => {
    if (setAbrirModal(true)) {
      setAbrirModal((current) => !current);
    } else {
      setAbrirModal((current) => current);
    }
  };

  return (
    <>
      {abrirModal && (
        <Modal btnFecharModal={btnFecharModal} className>
          <GalPessoas />
        </Modal>
      )}
      <Template>
        {ehDesktop && (
          <div className="pessoas_5 titulo_5">
            <h2 onClick={lidarComModal}>{pessoasDados[4].titulo}</h2>
          </div>
        )}
        {ehMobile && (
          <AnimatePresence>
            <motion.div
              className="pessoas_mob subcont_mob"
              variants={tempConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <div className="img_pag_mob" onClick={lidarComModal}>
                <picture>
                  <source
                    srcSet={pessoasdadosmob[2].srcWebp}
                    type="image/webp"
                  />
                  <source
                    srcSet={pessoasdadosmob[2].src}
                    type={`image/${pessoasdadosmob[2].type}`}
                  />
                  <img
                    src={pessoasdadosmob[2].src}
                    alt={pessoasdadosmob[2].alt}
                  />
                </picture>
                <div className="titulo_pag_mob">
                  <h2>Pessoas</h2>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        )}
      </Template>
    </>
  );
}
