import { HashRouter as Router } from "react-router-dom";
import "app.css";
import Navbar from "componentes/Navbar";
import AnimatedRoutes from "componentes/AnimatedRoutes";

function App() {
  return (
    <div className="mario_bhering_100_anos">
      <Router>
        {/* <IntroVideo /> */}
        <Navbar />
        <AnimatedRoutes />
      </Router>
    </div>
  );
}

export default App;
