import { motion, AnimatePresence } from "framer-motion";
import { useMediaQuery } from "react-responsive";
// estilo
import "estilo/paginas/home.css";
// dados desktop
import containerVari, {
  homeConteudoAnim,
  home1 as camadasxy,
} from "dados/home_dados_desktop";
// dados mobile
import { home1mob as camadasxymob } from "dados/home_dados_mobile";

export default function Home1() {
  const ehMobile = useMediaQuery({ query: "(max-width: 1025px)" });
  const topo_camada_mob = camadasxymob.slice(0, -1);
  const rodape_camada_mob = camadasxymob[camadasxymob.length - 1];

  return (
    <>
      {!ehMobile && (
        <AnimatePresence>
          <motion.section
            className="home home1"
            variants={containerVari}
            initial="iniciar"
            animate="animar"
          >
            {camadasxy.map((camadaXY, index) => {
              const camadaVari = {
                iniciar: {
                  x: `${camadaXY.x}vw`,
                  y: `${camadaXY.y}vh`,
                  opacity: camadaXY.opacA,
                },
                animar: {
                  x: 0,
                  y: 0,
                  opacity: camadaXY.opacZ,
                  transition: {
                    type: "Inertia",
                    duration: 1,
                  },
                },
              };
              return (
                <AnimatePresence key={camadaXY.id * Math.random()}>
                  <motion.div
                    className={`camada_${index + 1} camada`}
                    variants={camadaVari}
                    style={{ zIndex: 100 + index }}
                  >
                    {camadaXY.src && camadaXY.srcWebp && (
                      <picture>
                        <source srcSet={camadaXY.srcWebp} type="image/webp" />
                        <source
                          srcSet={camadaXY.src}
                          type={`image/${camadaXY.type}`}
                        />
                        <img src={camadaXY.src} alt={camadaXY.alt} />
                      </picture>
                    )}
                  </motion.div>
                </AnimatePresence>
              );
            })}
          </motion.section>
        </AnimatePresence>
      )}
      {ehMobile && (
        <main className="mobile">
          <AnimatePresence>
            <motion.header
              className="topo_mob"
              variants={containerVari}
              initial="iniciar"
              animate="animar"
            >
              {topo_camada_mob.map((camadaXY, index) => {
                const camadaVari = {
                  iniciar: {
                    x: `${camadaXY.x}vw`,
                    y: `${camadaXY.y}vh`,
                    opacity: camadaXY.opacA,
                  },
                  animar: {
                    x: 0,
                    y: 0,
                    opacity: camadaXY.opacZ,
                    transition: {
                      type: "Inertia",
                      duration: 1,
                    },
                  },
                };
                return (
                  <AnimatePresence key={camadaXY.id * Math.random()}>
                    <motion.div
                      className={`camada_mob_${index + 1} camada_mob`}
                      variants={camadaVari}
                      style={{ zIndex: 100 + index }}
                    >
                      {camadaXY.src && camadaXY.srcWebp && (
                        <picture>
                          <source srcSet={camadaXY.srcWebp} type="image/webp" />
                          <source
                            srcSet={camadaXY.src}
                            type={`image/${camadaXY.type}`}
                          />
                          <img src={camadaXY.src} alt={camadaXY.alt} />
                        </picture>
                      )}
                    </motion.div>
                  </AnimatePresence>
                );
              })}
            </motion.header>
          </AnimatePresence>
          <AnimatePresence>
            <motion.main
              className="conteudo_mob"
              variants={homeConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <div>
                <picture>
                  <source
                    srcSet="/img/home/1/mob/citacao1.webp"
                    type="image/webp"
                  />
                  <source
                    srcSet="/img/home/1/mob/citacao1.png"
                    type="image/png"
                  />
                  <img src="/img/home/1/mob/citacao1.png" alt="Citação" />
                </picture>
              </div>
            </motion.main>
          </AnimatePresence>
          <AnimatePresence>
            <motion.footer
              className="rodape_mob"
              variants={homeConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <picture>
                <source srcSet={rodape_camada_mob.srcWebp} type="image/webp" />
                <source
                  srcSet={rodape_camada_mob.src}
                  type={`image/${rodape_camada_mob.type}`}
                />
                <img src={rodape_camada_mob.src} alt={rodape_camada_mob.alt} />
              </picture>
            </motion.footer>
          </AnimatePresence>
        </main>
      )}
    </>
  );
}
