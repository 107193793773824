const containerVari = {
  iniciar: {
    opacity: 0,
    scale: 0,
    transition: {
      when: "afterChildren",
    },
  },
  animar: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.05,
      staggerChildren: 0.05,
      duration: 0.01,
      when: "beforeChildren",
    },
  },
  sair: {
    opacity: 0,
  },
};

export default containerVari;

export const home1 = [
  {
    id: 1,
    x: 0,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/fundo_home.jpg",
    srcWebp: "./img/home/0/fundo_home.webp",
    alt: "Textura clara de fundo",
    type: "jpeg",
  },
  {
    id: 2,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/camada_2.png",
    srcWebp: "./img/home/1/camada_2.webp",
    alt: "",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/camada_3.png",
    srcWebp: "./img/home/1/camada_3.webp",
    alt: "",
    type: "png",
  },
  {
    id: 4,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/camada_4.png",
    srcWebp: "./img/home/1/camada_4.webp",
    alt: "",
    type: "png",
  },
  {
    id: 5,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/camada_5.png",
    srcWebp: "./img/home/1/camada_5.webp",
    alt: "",
    type: "png",
  },
  {
    id: 6,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/camada_texto.png",
    srcWebp: "./img/home/1/camada_texto.webp",
    alt: "Frase de Mario Bhering",
    type: "png",
  },
  {
    id: 7,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 0.4,
    src: "./img/home/0/onda_de_linhas.png",
    srcWebp: "./img/home/0/onda_de_linhas.webp",
    alt: "Textura de linhas em ondas",
    type: "png",
  },
  {
    id: 8,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/assinatura.png",
    srcWebp: "./img/home/0/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
];

export const home2 = [
  {
    id: 1,
    x: 0,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/fundo_home.jpg",
    srcWebp: "./img/home/0/fundo_home.webp",
    alt: "Textura clara de fundo",
    type: "jpeg",
  },
  {
    id: 2,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/2/textura_azul_1.png",
    srcWebp: "./img/home/2/textura_azul_1.webp",
    alt: "Textura azul de fundo",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/2/textura_azul_2.png",
    srcWebp: "./img/home/2/textura_azul_2.webp",
    alt: "Outra textura azul de fundo",
    type: "png",
  },
  {
    id: 4,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/2/foto_familia.png",
    srcWebp: "./img/home/2/foto_familia.webp",
    alt: "Foto da família de Mario Bhering",
    type: "png",
  },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 0.4,
    src: "./img/home/0/onda_de_linhas.png",
    srcWebp: "./img/home/0/onda_de_linhas.webp",
    alt: "Textura de linhas em ondas",
    type: "png",
  },
  {
    id: 6,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/assinatura.png",
    srcWebp: "./img/home/0/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
];

export const home3 = [
  {
    id: 1,
    x: 0,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/fundo_home.jpg",
    srcWebp: "./img/home/0/fundo_home.webp",
    alt: "Textura clara de fundo",
    type: "jpeg",
  },
  {
    id: 2,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/3/textura_azul_2.png",
    srcWebp: "./img/home/3/textura_azul_2.webp",
    alt: "Textura azul de fundo",
    type: "png",
  },
  {
    id: 3,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/3/engenheiro_mario_bhering_um_artista.png",
    srcWebp: "./img/home/3/engenheiro_mario_bhering_um_artista.webp",
    alt: "Foto de Mario Bhering sorrindo",
    type: "png",
  },
  {
    id: 4,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/3/textura_azul_1.png",
    srcWebp: "./img/home/3/textura_azul_1.webp",
    alt: "Outra textura azul de fundo",
    type: "png",
  },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/3/textura_verde.png",
    srcWebp: "./img/home/3/textura_verde.webp",
    alt: "Textura verde de fundo",
    type: "png",
  },
  {
    id: 6,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 0.4,
    src: "./img/home/0/onda_de_linhas.png",
    srcWebp: "./img/home/0/onda_de_linhas.webp",
    alt: "Textura de linhas em ondas",
    type: "png",
  },
  {
    id: 7,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/assinatura.png",
    srcWebp: "./img/home/0/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
];

export const home4 = [
  {
    id: 1,
    x: 0,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/fundo_home.jpg",
    srcWebp: "./img/home/0/fundo_home.webp",
    alt: "Textura clara de fundo",
    type: "jpeg",
  },
  {
    id: 2,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/4/o_principio.png",
    srcWebp: "./img/home/4/o_principio.webp",
    alt: "Foto de Mario Bhering e família",
    type: "png",
  },
  {
    id: 3,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/4/textura_100_anos.png",
    srcWebp: "./img/home/4/textura_100_anos.webp",
    alt: "Texturas coloridas de fundo",
    type: "png",
  },
  {
    id: 4,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/4/textura_verde.png",
    srcWebp: "./img/home/4/textura_verde.webp",
    alt: "Textura verde de fundo",
    type: "png",
  },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 0.4,
    src: "./img/home/0/onda_de_linhas.png",
    srcWebp: "./img/home/0/onda_de_linhas.webp",
    alt: "Textura de linhas em ondas",
    type: "png",
  },
  {
    id: 6,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/assinatura.png",
    srcWebp: "./img/home/0/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
];

export const home5 = [
  {
    id: 1,
    x: 0,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/fundo_home.jpg",
    srcWebp: "./img/home/0/fundo_home.webp",
    alt: "Textura clara de fundo",
    type: "jpeg",
  },
  {
    id: 2,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/5/textura_azul_direita.png",
    srcWebp: "./img/home/5/textura_azul_direita.webp",
    alt: "Textura azul de fundo, à direita",
    type: "png",
  },
  {
    id: 3,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/5/a_inspiracaos.png",
    srcWebp: "./img/home/5/a_inspiracao.webp",
    alt: "Foto com a inspiração",
    type: "png",
  },
  {
    id: 4,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/5/textura_azul_baixo.png",
    srcWebp: "./img/home/5/textura_azul_baixo.webp",
    alt: "Outra textura azul de fundo (abaixo)",
    type: "png",
  },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 0.4,
    src: "./img/home/0/onda_de_linhas.png",
    srcWebp: "./img/home/0/onda_de_linhas.webp",
    alt: "Textura de linhas em ondas",
    type: "png",
  },
  {
    id: 6,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/assinatura.png",
    srcWebp: "./img/home/0/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
];

export const home6 = [
  {
    id: 1,
    x: 0,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/fundo_home.jpg",
    srcWebp: "./img/home/0/fundo_home.webp",
    alt: "Textura clara de fundo",
    type: "jpeg",
  },
  {
    id: 2,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/6/textura_azul_1.png",
    srcWebp: "./img/home/6/textura_azul_1.webp",
    alt: "Textura azul de fundo, à direita",
    type: "png",
  },
  {
    id: 3,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/6/textura_rosa.png",
    srcWebp: "./img/home/6/textura_rosa.webp",
    alt: "Textura rosa de fundo",
    type: "png",
  },
  {
    id: 4,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/6/textura_livro.png",
    srcWebp: "./img/home/6/textura_livro.webp",
    alt: "Textura colorida",
    type: "png",
  },
  {
    id: 5,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/6/textura_azul_2.png",
    srcWebp: "./img/home/6/textura_azul_2.webp",
    alt: "Outra textura azul",
    type: "png",
  },
  {
    id: 6,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 0.4,
    src: "./img/home/0/onda_de_linhas.png",
    srcWebp: "./img/home/0/onda_de_linhas.webp",
    alt: "Textura de linhas em ondas",
    type: "png",
  },
  {
    id: 7,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/assinatura.png",
    srcWebp: "./img/home/0/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
];

export const home7 = [
  {
    id: 1,
    x: 0,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/fundo_home.jpg",
    srcWebp: "./img/home/0/fundo_home.webp",
    alt: "Textura clara de fundo",
    type: "jpeg",
  },
  {
    id: 2,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/7/textura_azul_baixo.png",
    srcWebp: "./img/home/7/textura_azul_baixo.webp",
    alt: "Textura azul de fundo (abaixo)",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/7/textura_azul_direita.png",
    srcWebp: "./img/home/7/textura_azul_direita.webp",
    alt: "Outra textura azul de fundo (à direita)",
    type: "png",
  },
  {
    id: 4,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/7/textura_colorida_documentario.png",
    srcWebp: "./img/home/7/textura_colorida_documentario.webp",
    alt: "Textura colorida de fundo com 100 anos",
    type: "png",
  },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 0.4,
    src: "./img/home/0/onda_de_linhas.png",
    srcWebp: "./img/home/0/onda_de_linhas.webp",
    alt: "Textura de linhas em ondas",
    type: "png",
  },
  {
    id: 6,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/assinatura.png",
    srcWebp: "./img/home/0/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
];

export const home7videos = [
  {
    id: 1,
    url: "UBL42hX3_iA",
    src: "./img/home/video_thumb.jpg",
    srcWebp: "./img/home/video_thumb.webp",
    alt: "Vídeo thumb",
    titulo: "Depoimentos (curto)",
  },
  {
    id: 2,
    url: "UBL42hX3_iA",
    src: "./img/home/video_thumb.jpg",
    srcWebp: "./img/home/video_thumb.webp",
    alt: "Vídeo thumb",
    titulo: "Depoimentos (completo)",
  },
];

export const homeConteudoAnim = {
  iniciar: {
    opacity: 0,
  },
  animar: {
    opacity: 1,
    transition: {
      delay: 1.25,
      duration: 2,
    },
  },
  sair: {
    opacity: 0,
  },
};
