const containerVari = {
  iniciar: {
    opacity: 0,
    scale: 0,
    transition: {
      when: "afterChildren",
    },
  },
  animar: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.05,
      staggerChildren: 0.05,
      duration: 0.01,
      when: "beforeChildren",
    },
  },
  sair: {
    opacity: 0,
  },
};

export default containerVari;

export const naturezaDados = [
  {
    id: 1,
    x: 0,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/fundo_home.jpg",
    srcWebp: "./img/home/0/fundo_home.webp",
    alt: "Textura clara de fundo",
    type: "jpeg",
    classe: "natureza",
    titulo: "",
    modal: "",
  },
  {
    id: 2,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/natureza/textura_verde_esquerda.png",
    srcWebp: "./img/natureza/textura_verde_esquerda.webp",
    alt: "Textura verde de fundo (à esquerda)",
    type: "png",
    classe: "natureza",
    titulo: "",
    modal: "",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/natureza/textura_verde_direita.png",
    srcWebp: "./img/natureza/textura_verde_direita.webp",
    alt: "Textura verde de fundo (à direita)",
    type: "png",
    classe: "natureza",
    titulo: "",
    modal: "",
  },
  {
    id: 4,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/natureza/100_anos_natureza.png",
    srcWebp: "./img/natureza/100_anos_natureza.webp",
    alt: "Textura de fundo (abaixo)",
    type: "png",
    classe: "natureza",
    titulo: "",
    modal: "",
  },
  {
    id: 5,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/natureza/natureza_direita.png",
    srcWebp: "./img/natureza/natureza_direita.webp",
    alt: "Textura verde mesclada com flores",
    type: "png",
    classe: "natureza",
    titulo: "Plantas",
    modal: ".",
  },
  {
    id: 6,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/natureza/natureza_esquerda.png",
    srcWebp: "./img/natureza/natureza_esquerda.webp",
    alt: "Textura verde mesclada com uma casa",
    type: "png",
    classe: "natureza",
    titulo: "Lugares na natureza",
    modal: ".",
  },
  {
    id: 7,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 0.4,
    src: "./img/home/0/onda_de_linhas.png",
    srcWebp: "./img/home/0/onda_de_linhas.webp",
    alt: "Textura de linhas em ondas",
    type: "png",
    classe: "natureza",
    titulo: "",
    modal: "",
  },
  {
    id: 8,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/assinatura.png",
    srcWebp: "./img/home/0/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
    classe: "natureza",
    titulo: "",
    modal: "",
  },
];

export const oMarDados = [
  {
    id: 1,
    x: 0,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/fundo_home.jpg",
    srcWebp: "./img/home/0/fundo_home.webp",
    alt: "Textura clara de fundo",
    type: "jpeg",
    classe: "o_mar",
    titulo: "",
    modal: "",
  },
  {
    id: 2,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/omar/textura_azul_direita.png",
    srcWebp: "./img/omar/textura_azul_direita.webp",
    alt: "Textura azul de fundo (à direita)",
    type: "png",
    classe: "o_mar",
    titulo: "",
    modal: "",
  },
  {
    id: 3,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/omar/textura_o_mar.png",
    srcWebp: "./img/omar/textura_o_mar.webp",
    alt: "Textura azul e marca de 100 anos (à esquerda)",
    type: "png",
    classe: "o_mar",
    titulo: "",
    modal: "",
  },
  {
    id: 4,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/omar/textura_fascinio.png",
    srcWebp: "./img/omar/textura_fascinio.webp",
    alt: "Textura mesclada com barcos",
    type: "png",
    classe: "o_mar",
    titulo: "Fascínio pelo mar",
    modal: ".",
  },
  {
    id: 5,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/omar/textura_miudezas.png",
    srcWebp: "./img/omar/textura_miudezas.webp",
    alt: "Textura miudezas do mar",
    type: "png",
    classe: "o_mar",
    titulo: "Miudezas",
    modal: ".",
  },
  {
    id: 6,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/omar/textura_o_cotidiano.png",
    srcWebp: "./img/omar/textura_o_cotidiano.webp",
    alt: "Textura mesclada com um pescador",
    type: "png",
    classe: "o_mar",
    titulo: "O cotidiano à beira mar",
    modal: ".",
  },
  {
    id: 7,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 0.4,
    src: "./img/home/0/onda_de_linhas.png",
    srcWebp: "./img/home/0/onda_de_linhas.webp",
    alt: "Textura de linhas em ondas",
    type: "png",
    classe: "o_mar",
    titulo: "",
    modal: "",
  },
  {
    id: 8,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/assinatura.png",
    srcWebp: "./img/home/0/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
    classe: "o_mar",
    titulo: "",
    modal: "",
  },
];

export const pessoasDados = [
  {
    id: 1,
    x: 0,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/fundo_home.jpg",
    srcWebp: "./img/home/0/fundo_home.webp",
    alt: "Textura clara de fundo",
    type: "jpeg",
    classe: "pessoas",
    titulo: "",
    modal: "",
  },
  {
    id: 2,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/pessoas/textura_roxa_direita_1.png",
    srcWebp: "./img/pessoas/textura_roxa_direita_1.webp",
    alt: "Textura roxa de fundo (à direita)",
    type: "png",
    classe: "pessoas",
    titulo: "",
    modal: "",
  },
  {
    id: 3,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/pessoas/textura_pessoas_100_anos.png",
    srcWebp: "./img/pessoas/textura_pessoas_100_anos.webp",
    alt: "Textura roxa e marca de 100 anos (à esquerda)",
    type: "png",
    classe: "pessoas",
    titulo: "",
    modal: "",
  },
  {
    id: 4,
    x: 100,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/pessoas/textura_roxa_direita_2.png",
    srcWebp: "./img/pessoas/textura_roxa_direita_2.webp",
    alt: "Textura roxa de fundo (parte de baixo da tela)",
    type: "png",
    classe: "pessoas",
    titulo: "",
    modal: ".",
  },
  {
    id: 5,
    x: 100,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/pessoas/pessoas_escolha.png",
    srcWebp: "./img/pessoas/pessoas_escolha.webp",
    alt: "Textura misturada com a pintura de um rosto feminino",
    type: "png",
    classe: "pessoas",
    titulo: "Pessoas",
    modal: ".",
  },
  {
    id: 6,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 0.4,
    src: "./img/home/0/onda_de_linhas.png",
    srcWebp: "./img/home/0/onda_de_linhas.webp",
    alt: "Textura de linhas em ondas",
    type: "png",
    classe: "pessoas",
    titulo: "",
    modal: "",
  },
  {
    id: 7,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/assinatura.png",
    srcWebp: "./img/home/0/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
    classe: "pessoas",
    titulo: "",
    modal: "",
  },
];

export const usinasDados = [
  {
    id: 1,
    x: 0,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/fundo_home.jpg",
    srcWebp: "./img/home/0/fundo_home.webp",
    alt: "Textura clara de fundo",
    type: "jpeg",
    classe: "usinas",
    titulo: "",
    modal: "",
  },
  {
    id: 2,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/usinas/textura_roxa_direita.png",
    srcWebp: "./img/usinas/textura_roxa_direita.webp",
    alt: "Textura roxa (à direita)",
    type: "png",
    classe: "usinas",
    titulo: "",
    modal: "",
  },
  {
    id: 3,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/usinas/textura_roxa_baixo.png",
    srcWebp: "./img/usinas/textura_roxa_baixo.png.webp",
    alt: "Textura roxa (na parte de baixo da tela)",
    type: "png",
    classe: "usinas",
    titulo: "",
    modal: "",
  },
  {
    id: 4,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/usinas/textura_100_anos.png",
    srcWebp: "./img/usinas/textura_100_anos.png",
    alt: "Textura e marca de 100 anos (à esquerda)",
    type: "png",
    classe: "usinas",
    titulo: "",
    modal: "",
  },
  {
    id: 5,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/usinas/usinas_escolha.png",
    srcWebp: "./img/usinas/usinas_escolha.webp",
    alt: "Textura misturada com a pintura de uma usina hidroelétrica",
    type: "png",
    classe: "usinas",
    titulo: "Usinas",
    modal: ".",
  },
  {
    id: 6,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 0.4,
    src: "./img/home/0/onda_de_linhas.png",
    srcWebp: "./img/home/0/onda_de_linhas.webp",
    alt: "Textura de linhas em ondas",
    type: "png",
    classe: "usinas",
    titulo: "",
    modal: "",
  },
  {
    id: 7,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/assinatura.png",
    srcWebp: "./img/home/0/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
    classe: "usinas",
    titulo: "",
    modal: "",
  },
];

export const marioDados = [
  {
    id: 1,
    x: 0,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/fundo_home.jpg",
    srcWebp: "./img/home/0/fundo_home.webp",
    alt: "Textura clara de fundo",
    type: "jpeg",
    classe: "usinas",
    titulo: "",
    modal: "",
  },
  {
    id: 2,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/mario/textura_verde_esquerda.png",
    srcWebp: "./img/mario/textura_verde_esquerda.webp",
    alt: "Textura verde (à esquerda da tela)",
    type: "png",
    classe: "mario",
    titulo: "",
    modal: "",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/mario/textura_verde_direita.png",
    srcWebp: "./img/mario/textura_verde_direita.webp",
    alt: "Textura verde (à direita da tela)",
    type: "png",
    classe: "mario",
    titulo: "",
    modal: "",
  },
  {
    id: 4,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/mario/mario_fundo.png",
    srcWebp: "./img/mario/mario_fundo.webp",
    alt: "Textura de fundo combinada com um autorretrato de Mario Bhering",
    type: "png",
    classe: "mario",
    titulo: "Autorretrato",
    modal: ".",
  },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/mario/textura_mario.png",
    srcWebp: "./img/mario/textura_mario.webp",
    alt: "Textura e marca de 100 anos (parte de baixo da tela)",
    type: "png",
    classe: "mario",
    titulo: "",
    modal: "",
  },
  {
    id: 6,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 0.4,
    src: "./img/home/0/onda_de_linhas.png",
    srcWebp: "./img/home/0/onda_de_linhas.webp",
    alt: "Textura de linhas em ondas",
    type: "png",
    classe: "mario",
    titulo: "",
    modal: "",
  },
  {
    id: 7,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/assinatura.png",
    srcWebp: "./img/home/0/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
    classe: "mario",
    titulo: "",
    modal: "",
  },
];

export const expedienteDados = [
  {
    id: 1,
    x: 0,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/fundo_home.jpg",
    srcWebp: "./img/home/0/fundo_home.webp",
    alt: "Textura clara de fundo",
    type: "jpeg",
    classe: "usinas",
  },
  {
    id: 2,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/expediente/textura_verde_esquerda.png",
    srcWebp: "./img/expediente/textura_verde_esquerda.webp",
    alt: "Textura verde (à esquerda da tela)",
    type: "png",
    classe: "expediente",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/expediente/textura_azul_direita.png",
    srcWebp: "./img/expediente/textura_azul_direita.webp",
    alt: "Textura azul (à direita da tela)",
    type: "png",
    classe: "expediente",
  },
  {
    id: 4,
    x: 0,
    y: 100,
    opacA: 0,
    opacZ: 1,
    src: "./img/expediente/textura_laranja_baixo.png",
    srcWebp: "./img/expediente/textura_laranja_baixo.webp",
    alt: "Textura laranja (na parte de baixo da tela)",
    type: "png",
    classe: "expediente",
  },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/expediente/textura_laranja_baixo.png",
    srcWebp: "./img/expediente/textura_laranja_baixo.webp",
    alt: "Textura roxa (na parte de baixo da tela)",
    type: "png",
    classe: "expediente",
  },
  {
    id: 6,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 0.4,
    src: "./img/home/0/onda_de_linhas.png",
    srcWebp: "./img/home/0/onda_de_linhas.webp",
    alt: "Textura de linhas em ondas",
    type: "png",
    classe: "mario",
  },
];

export const expedienteLogos = [
  {
    id: 1,
    src: "./img/expediente/engevisa.png",
    alt: "Logo Engevisa",
  },
  {
    id: 2,
    src: "./img/expediente/furnas.png",
    alt: "Logo Furnas",
  },
  {
    id: 3,
    src: "./img/expediente/itaipu.png",
    alt: "Logo Itaipu",
  },
  {
    id: 4,
    src: "./img/expediente/norte_energia.png",
    alt: "Logo Norte Energia",
  },
];

export const tempConteudoAnim = {
  iniciar: {
    opacity: 0,
  },
  animar: {
    opacity: 1,
    transition: {
      delay: 1.25,
      duration: 2,
    },
  },
  sair: {
    opacity: 0,
  },
};
