// let posicaoAleatoriaX = Math.floor(Math.random() * 100) + 1;

let dadosGalNaturezaPlantas = [
  {
    src: "./img/natureza/galeria/natureza_foto1.jpg",
    srcWebp: "./img/natureza/galeria/natureza_foto1.webp",
    alt: "imagem 1",
    id: 1,
    id2: 10,
    x: -1000,
    y: 0,
    paraX: [-1000, 0],
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/natureza/galeria/natureza_foto2.jpg",
    srcWebp: "./img/natureza/galeria/natureza_foto2.webp",
    alt: "imagem 2",
    id: 2,
    id2: 20,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: [-1000, 0],
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/natureza/galeria/natureza_foto3.jpg",
    srcWebp: "./img/natureza/galeria/natureza_foto3.webp",
    alt: "imagem 3",
    id: 3,
    id2: 30,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/natureza/galeria/citacao1_natureza.png",
    srcWebp: "./img/natureza/galeria/citacao1_natureza.webp",
    alt: "Citação de Mario Bhering",
    id: 31,
    id2: 310,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "png",
  },
  {
    src: "./img/natureza/galeria/natureza_foto4.jpg",
    srcWebp: "./img/natureza/galeria/natureza_foto4.webp",
    alt: "imagem 4",
    id: 4,
    id2: 40,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/natureza/galeria/natureza_foto5.jpg",
    srcWebp: "./img/natureza/galeria/natureza_foto5.webp",
    alt: "imagem 5",
    id: 5,
    id2: 50,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
];

export default dadosGalNaturezaPlantas;

export let dadosGalNaturezaLugares = [
  {
    src: "./img/natureza/galeria/lugares_foto1.jpg",
    srcWebp: "./img/natureza/galeria/lugares_foto1.webp",
    alt: "imagem 1",
    id: 1,
    id2: 10,
    x: 1000,
    y: 0,
    paraX: [1000, 0],
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/natureza/galeria/lugares_foto2.jpg",
    srcWebp: "./img/natureza/galeria/lugares_foto2.webp",
    alt: "imagem 2",
    id: 2,
    id2: 20,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: [-1000, 0],
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/natureza/galeria/lugares_foto3.jpg",
    srcWebp: "./img/natureza/galeria/lugares_foto3.webp",
    alt: "imagem 3",
    id: 3,
    id2: 30,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/natureza/galeria/citacao1_natureza.png",
    srcWebp: "./img/natureza/galeria/citacao1_natureza.webp",
    alt: "Citação de Mario Bhering",
    id: 31,
    id2: 310,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "png",
  },
  {
    src: "./img/natureza/galeria/lugares_foto4.jpg",
    srcWebp: "./img/natureza/galeria/lugares_foto4.webp",
    alt: "imagem 4",
    id: 4,
    id2: 40,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/natureza/galeria/lugares_foto5.jpg",
    srcWebp: "./img/natureza/galeria/lugares_foto5.webp",
    alt: "imagem 5",
    id: 5,
    id2: 50,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/natureza/galeria/lugares_foto6.jpg",
    srcWebp: "./img/natureza/galeria/lugares_foto6.webp",
    alt: "imagem 6",
    id: 6,
    id2: 60,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/natureza/galeria/lugares_foto7.jpg",
    srcWebp: "./img/natureza/galeria/lugares_foto7.webp",
    alt: "imagem 7",
    id: 7,
    id2: 70,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/natureza/galeria/lugares_foto8.jpg",
    srcWebp: "./img/natureza/galeria/lugares_foto8.webp",
    alt: "imagem 8",
    id: 8,
    id2: 80,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
];

export let dadosGalOMarMiudezas = [
  {
    src: "./img/omar/galeria/miudezas_foto1.jpg",
    srcWebp: "./img/omar/galeria/miudezas_foto1.webp",
    alt: "imagem 1",
    id: 1,
    id2: 10,
    x: 10000,
    y: 0,
    paraX: [1000, 0],
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/miudezas_foto2.jpg",
    srcWebp: "./img/omar/galeria/miudezas_foto2.webp",
    alt: "imagem 2",
    id: 2,
    id2: 20,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: [-1000, 0],
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/citacao1.png",
    srcWebp: "./img/omar/galeria/citacao1.webp",
    alt: "Citação de Mario Bhering",
    id: 31,
    id2: 310,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "png",
  },
  {
    src: "./img/omar/galeria/miudezas_foto3.jpg",
    srcWebp: "./img/omar/galeria/miudezas_foto3.webp",
    alt: "imagem 3",
    id: 3,
    id2: 30,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/miudezas_foto4.jpg",
    srcWebp: "./img/omar/galeria/miudezas_foto4.webp",
    alt: "imagem 4",
    id: 4,
    id2: 40,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/miudezas_foto5.jpg",
    srcWebp: "./img/omar/galeria/miudezas_foto5.webp",
    alt: "imagem 5",
    id: 5,
    id2: 50,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
];

export let dadosGalOMarCotidiano = [
  {
    src: "./img/omar/galeria/cotidiano_foto1.jpg",
    srcWebp: "./img/omar/galeria/cotidiano_foto1.webp",
    alt: "imagem 1",
    id: 1,
    id2: 10,
    x: 0,
    y: 10000,
    paraX: 0,
    paraY: [1000, 0],
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/cotidiano_foto2.jpg",
    srcWebp: "./img/omar/galeria/cotidiano_foto2.webp",
    alt: "imagem 2",
    id: 2,
    id2: 20,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: [-1000, 0],
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/cotidiano_foto3.jpg",
    srcWebp: "./img/omar/galeria/cotidiano_foto3.webp",
    alt: "imagem 3",
    id: 3,
    id2: 30,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/cotidiano_foto4.jpg",
    srcWebp: "./img/omar/galeria/cotidiano_foto4.webp",
    alt: "imagem 4",
    id: 4,
    id2: 40,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/cotidiano_foto5.jpg",
    srcWebp: "./img/omar/galeria/cotidiano_foto5.webp",
    alt: "imagem 5",
    id: 5,
    id2: 50,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/cotidiano_foto6.jpg",
    srcWebp: "./img/omar/galeria/cotidiano_foto6.webp",
    alt: "imagem 6",
    id: 6,
    id2: 60,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/cotidiano_foto7.jpg",
    srcWebp: "./img/omar/galeria/cotidiano_foto7.webp",
    alt: "imagem 7",
    id: 7,
    id2: 70,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/cotidiano_foto8.jpg",
    srcWebp: "./img/omar/galeria/cotidiano_foto8.webp",
    alt: "imagem 8",
    id: 8,
    id2: 80,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
];

export let dadosGalOMarFascinio = [
  {
    src: "./img/omar/galeria/fascinio_foto1.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto1.webp",
    alt: "imagem 1",
    id: 1,
    id2: 10,
    x: -10000,
    y: 0,
    paraX: [-1000, 0],
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto2.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto2.webp",
    alt: "imagem 2",
    id: 2,
    id2: 20,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: [-1000, 0],
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto3.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto3.webp",
    alt: "imagem 3",
    id: 3,
    id2: 30,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto4.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto4.webp",
    alt: "imagem 4",
    id: 4,
    id2: 40,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto5.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto5.webp",
    alt: "imagem 5",
    id: 5,
    id2: 50,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto6.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto6.webp",
    alt: "imagem 6",
    id: 6,
    id2: 60,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto7.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto7.webp",
    alt: "imagem 7",
    id: 7,
    id2: 70,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/citacao3.png",
    srcWebp: "./img/omar/galeria/citacao3.webp",
    alt: "Citação de Mario Bhering",
    id: 71,
    id2: 710,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "png",
  },
  {
    src: "./img/omar/galeria/fascinio_foto8.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto8.webp",
    alt: "imagem 8",
    id: 8,
    id2: 80,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto9.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto9.webp",
    alt: "imagem 9",
    id: 9,
    id2: 90,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto10.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto10.webp",
    alt: "imagem 10",
    id: 10,
    id2: 100,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto11.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto11.webp",
    alt: "imagem 11",
    id: 11,
    id2: 110,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto12.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto12.webp",
    alt: "imagem 12",
    id: 12,
    id2: 120,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto13.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto13.webp",
    alt: "imagem 13",
    id: 13,
    id2: 130,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto14.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto14.webp",
    alt: "imagem 14",
    id: 14,
    id2: 140,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto15.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto15.webp",
    alt: "imagem 15",
    id: 15,
    id2: 150,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/omar/galeria/fascinio_foto16.jpg",
    srcWebp: "./img/omar/galeria/fascinio_foto16.webp",
    alt: "imagem 16",
    id: 16,
    id2: 160,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
];

export let dadosGalPessoas = [
  {
    src: "./img/pessoas/galeria/pessoas_foto1.jpg",
    srcWebp: "./img/pessoas/galeria/pessoas_foto1.webp",
    alt: "imagem 1",
    id: 1,
    id2: 10,
    x: 10000,
    y: 0,
    paraX: [1000, 0],
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/pessoas/galeria/pessoas_foto2.jpg",
    srcWebp: "./img/pessoas/galeria/pessoas_foto2.webp",
    alt: "imagem 2",
    id: 2,
    id2: 20,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: [-1000, 0],
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/pessoas/galeria/pessoas_foto3.jpg",
    srcWebp: "./img/pessoas/galeria/pessoas_foto3.webp",
    alt: "imagem 3",
    id: 3,
    id2: 30,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
];

export let dadosGalUsinas = [
  {
    src: "./img/usinas/galeria/usinas_foto1.jpg",
    srcWebp: "./img/usinas/galeria/usinas_foto1.webp",
    alt: "imagem 1",
    id: 1,
    id2: 10,
    x: -100,
    y: 0,
    paraX: [-100, 0],
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/usinas/galeria/usinas_foto2.jpg",
    srcWebp: "./img/usinas/galeria/usinas_foto2.webp",
    alt: "imagem 2",
    id: 2,
    id2: 20,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: [-1000, 0],
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/usinas/galeria/usinas_foto4.jpg",
    srcWebp: "./img/usinas/galeria/usinas_foto4.webp",
    alt: "imagem 4",
    id: 4,
    id2: 40,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },

  {
    src: "./img/usinas/galeria/citacao1_usina.png",
    srcWebp: "./img/usinas/galeria/citacao1_usina.png",
    alt: "Citação de Mario Bhering",
    id: 41,
    id2: 410,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "png",
  },
  {
    src: "./img/usinas/galeria/usinas_foto5.jpg",
    srcWebp: "./img/usinas/galeria/usinas_foto5.webp",
    alt: "imagem 5",
    id: 5,
    id2: 50,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/usinas/galeria/usinas_foto6.jpg",
    srcWebp: "./img/usinas/galeria/usinas_foto6.webp",
    alt: "imagem 6",
    id: 6,
    id2: 60,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/usinas/galeria/usinas_foto7.jpg",
    srcWebp: "./img/usinas/galeria/usinas_foto7.webp",
    alt: "imagem 7",
    id: 7,
    id2: 70,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/usinas/galeria/usinas_foto8.jpg",
    srcWebp: "./img/usinas/galeria/usinas_foto8.webp",
    alt: "imagem 8",
    id: 8,
    id2: 80,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/usinas/galeria/usinas_foto11.jpg",
    srcWebp: "./img/usinas/galeria/usinas_foto11.webp",
    alt: "imagem 11",
    id: 11,
    id2: 110,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/usinas/galeria/usinas_foto12.jpg",
    srcWebp: "./img/usinas/galeria/usinas_foto12.webp",
    alt: "imagem 12",
    id: 12,
    id2: 120,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
];

export let dadosMario = [
  {
    src: "./img/mario/galeria/mario_foto1.jpg",
    srcWebp: "./img/mario/galeria/mario_foto1.webp",
    alt: "imagem 1",
    id: 1,
    id2: 10,
    x: 0,
    y: 1000,
    paraX: 0,
    paraY: [1000, 0],
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
  {
    src: "./img/mario/galeria/citacao.png",
    srcWebp: "./img/mario/galeria/citacao.webp",
    alt: "imagem 2",
    id: 2,
    id2: 20,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: 0,
    sairX: 0,
    sairY: 0,
    tipo: "png",
  },
  {
    src: "./img/mario/galeria/mario_foto2.jpg",
    srcWebp: "./img/mario/galeria/mario_foto2.webp",
    alt: "imagem 3",
    id: 3,
    id2: 30,
    x: -100,
    y: 0,
    paraX: 0,
    paraY: [-1000, 0],
    sairX: 0,
    sairY: 0,
    tipo: "jpeg",
  },
];

export const containerSliderVari = {
  iniciar: {
    scale: 1,
    opacity: 0.5,
    backgroundColor: "black",
  },
  animar: {
    scale: 1,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,0.8)",
    transition: {
      // yoyo: 10, Infinity
      // type: "spring",
      // mass: 0.4,
      // damping: 50,
      delay: 0.2,
      duration: 2.5,
      when: "beforeChildren",
      staggerChildren: 0.25, // seconds for every children
    },
  },
};

export const slideVari = {
  iniciar: {
    opacity: 0,
    scale: 0,
    x: "-250vw",
  },
  animar: {
    opacity: 1,
    scale: 1,
    x: 0,
  },
};
