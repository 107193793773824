import { useState } from "react";
import "estilo/componentes/tooltip.css";

export default function Tooltip(props) {
  let timeout;
  const [tooltipAtiva, setTooltipAtiva] = useState(false);

  const tooltipMostrar = () => {
    timeout = setTimeout(() => {
      setTooltipAtiva(true);
    }, props.delay || 200);
  };

  const tooltipEsconder = () => {
    clearInterval(timeout);
    setTooltipAtiva(false);
  };

  return (
    <div onMouseEnter={tooltipMostrar} onMouseLeave={tooltipEsconder}>
      {props.children}
      {tooltipAtiva && <div className="tooltip">{props.content}</div>}
    </div>
  );
}
