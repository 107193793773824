import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/thumbs";
// dados
import {
  dadosMario,
  containerSliderVari,
  slideVari,
} from "dados/galeria_dados";
// estilo
import "estilo/componentes/galeria.css";

export default function GalMario() {
  const [imagesNavSlider, setImagesNavSlider] = useState(null);

  return (
    <motion.section
      className="slider"
      variants={containerSliderVari}
      initial="iniciar"
      animate="animar"
      exit={{ opacity: 0, duration: 1.5 }}
    >
      <div className="slider__flex">
        <div className="slider__col">
          <div className="slider__prev">
            <img src="./img/navbar/seta-cima.png" alt="seta para cima" />
          </div>

          <div className="slider__thumbs">
            <Swiper
              onSwiper={setImagesNavSlider}
              direction="vertical"
              spaceBetween={24}
              slidesPerView={5}
              navigation={{
                nextEl: ".slider__next",
                prevEl: ".slider__prev",
              }}
              className="swiper-container1"
              breakpoints={{
                0: {
                  direction: "horizontal",
                },
                768: {
                  direction: "vertical",
                },
              }}
              modules={[Navigation, Thumbs]}
            >
              {dadosMario.map((imgGal) => {
                return (
                  <SwiperSlide key={imgGal.id}>
                    <motion.div className="slider__image" variants={slideVari}>
                      {imgGal.src && <img src={imgGal.src} alt={imgGal.alt} />}
                    </motion.div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          <div className="slider__next">
            <img src="./img/navbar/seta-baixo.png" alt="seta para baixo" />
          </div>
        </div>

        <div className="slider__images">
          <Swiper
            thumbs={{
              swiper:
                imagesNavSlider && !imagesNavSlider.destroyed
                  ? imagesNavSlider
                  : null,
            }}
            direction="horizontal"
            slidesPerView={1}
            spaceBetween={10}
            navigation={{
              nextEl: ".slider__next",
              prevEl: ".slider__prev",
            }}
            breakpoints={{
              0: {
                direction: "horizontal",
              },
              768: {
                direction: "horizontal",
              },
            }}
            className="swiper-container2"
            modules={[EffectFade, Navigation, Thumbs]}
            effect="fade"
          >
            {dadosMario.map((imgGal) => {
              return (
                <AnimatePresence key={imgGal.id2}>
                  <SwiperSlide>
                    {({ isActive }) => (
                      <motion.div
                        initial={{
                          opacity: isActive ? 0 : 0,
                          scale: isActive ? 0 : 0,
                          x: `${imgGal.x}`,
                          y: `${imgGal.y}`,
                        }}
                        animate={{
                          opacity: isActive ? [0.25, 0.5, 1] : 0,
                          scale: isActive ? 1 : 0,
                          x: isActive ? imgGal.paraX : 0,
                          y: isActive ? imgGal.paraY : 0,
                          transition: {
                            duration: 2.5,
                            delay: 0.2,
                          },
                        }}
                        exit={{
                          opacity: !isActive ? 0 : 0,
                          duration: !isActive ? 1.5 : 0,
                        }}
                        className="slider__image"
                      >
                        {imgGal.src && imgGal.srcWebp && (
                          <picture>
                            <source srcSet={imgGal.srcWebp} type="image/webp" />
                            <source
                              srcSet={imgGal.src}
                              type={`image/${imgGal.tipo}`}
                            />
                            <img src={imgGal.src} alt={imgGal.alt} />
                          </picture>
                        )}
                        {!imgGal.src && (
                          <div className="slider_frase">
                            <h3>{imgGal.frase}</h3>
                          </div>
                        )}
                      </motion.div>
                    )}
                  </SwiperSlide>
                </AnimatePresence>
              );
            })}
          </Swiper>
        </div>
      </div>
    </motion.section>
  );
}
