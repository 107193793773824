import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
// template e estilo
import Template from "paginas/Template";
import "estilo/paginas/template.css";
// componentes
import Modal from "componentes/Modal";
import GalMario from "componentes/galerias/GalMario";
// dados desktop
import { marioDados, tempConteudoAnim } from "dados/paginas_dados_desktop";
// dados mobile
import { mariodadosmob } from "dados/paginas_dados_mobile";

export default function MarioBhering({ children }) {
  const ehMobile = useMediaQuery({ query: "(max-width: 1025px)" });
  const [abrirModal, setAbrirModal] = useState(false);

  const btnFecharModal = () => {
    if (setAbrirModal(true)) {
      setAbrirModal((current) => !current);
    } else if (setAbrirModal(false)) {
      setAbrirModal((current) => current);
    }
  };

  const lidarComModal = () => {
    if (setAbrirModal(true)) {
      setAbrirModal((current) => !current);
    } else {
      setAbrirModal((current) => current);
    }
  };

  return (
    <>
      {abrirModal && (
        <Modal btnFecharModal={btnFecharModal} className>
          <GalMario />
        </Modal>
      )}
      <Template>
        {!ehMobile && (
          <div className="mario_4 titulo_4">
            <h2 onClick={lidarComModal}>{marioDados[3].titulo}</h2>
          </div>
        )}
        {ehMobile && (
          <AnimatePresence>
            <motion.div
              className="mario_mob subcont_mob"
              variants={tempConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <div className="img_pag_mob" onClick={lidarComModal}>
                <picture>
                  <source srcSet={mariodadosmob[2].srcWebp} type="image/webp" />
                  <source
                    srcSet={mariodadosmob[2].src}
                    type={`image/${mariodadosmob[2].type}`}
                  />
                  <img src={mariodadosmob[2].src} alt={mariodadosmob[2].alt} />
                </picture>
                <div className="titulo_pag_mob">
                  <h2>Autorretrato</h2>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        )}
      </Template>
    </>
  );
}
