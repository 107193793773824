import { Routes, Route, useLocation } from "react-router-dom";

import Home1 from "paginas/home/Home1";
import Home2 from "paginas/home/Home2";
import Home3 from "paginas/home/Home3";
import Home4 from "paginas/home/Home4";
import Home5 from "paginas/home/Home5";
import Home6 from "paginas/home/Home6";
import Home7 from "paginas/home/Home7";
import Usinas from "paginas/outras/Usinas";
import Expediente from "paginas/outras/Expediente";
import Pessoas from "paginas/outras/Pessoas";
import Natureza from "paginas/outras/Natureza";
import MarioBhering from "paginas/outras/MarioBhering";
import OMar from "paginas/outras/OMar";

import IntroVideo from "componentes/IntroVideo";
// import Erro from ".././Paginas/Erro";

import { AnimatePresence } from "framer-motion";

export default function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<IntroVideo />} />
        <Route path="/Home">
          <Route index path="1" element={<Home1 />} />
          <Route path="2" element={<Home2 />} />
          <Route path="3" element={<Home3 />} />
          <Route path="4" element={<Home4 />} />
          <Route path="5" element={<Home5 />} />
          <Route path="6" element={<Home6 />} />
          <Route path="7" element={<Home7 />} />
          <Route path="/Home/:id" />
        </Route>
        <Route path="/Usinas" element={<Usinas />} />
        <Route path="/Expediente" element={<Expediente />} />
        <Route path="/Pessoas" element={<Pessoas />} />
        <Route path="/Natureza" element={<Natureza />} />
        <Route path="/OMar" element={<OMar />} />
        <Route path="/MarioPorMario" element={<MarioBhering />} />
      </Routes>
    </AnimatePresence>
  );
}
