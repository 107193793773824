import { motion, AnimatePresence } from "framer-motion";
import { useMediaQuery } from "react-responsive";
// estilo
import "estilo/paginas/home.css";
// dados desktop
import containerVari, {
  home3 as camadasxy,
  homeConteudoAnim,
} from "dados/home_dados_desktop";
import Conteudo from "dados/home3_conteudo";
// dados mobile
import { home3mob as camadasxymob } from "dados/home_dados_mobile";

export default function Home3() {
  const ehMobile = useMediaQuery({ query: "(max-width: 1025px)" });
  const topo_camada_mob = camadasxymob.slice(0, -1);
  const rodape_camada_mob = camadasxymob[camadasxymob.length - 1];
  return (
    <>
      {!ehMobile && (
        <AnimatePresence>
          <motion.section
            className="home"
            variants={containerVari}
            initial="iniciar"
            animate="animar"
          >
            {camadasxy.map((camadaxy, index) => {
              const camadaVari = {
                iniciar: {
                  x: `${camadaxy.x}vw`,
                  y: `${camadaxy.y}vh`,
                  opacity: camadaxy.opacA,
                },
                animar: {
                  x: 0,
                  y: 0,
                  opacity: camadaxy.opacZ,
                  transition: {
                    type: "Inertia",
                    duration: 1,
                  },
                },
              };
              return (
                <AnimatePresence key={camadaxy.id}>
                  <motion.div
                    className={`camada_${index + 1} camada`}
                    variants={camadaVari}
                  >
                    {camadaxy.src && camadaxy.srcWebp && (
                      <picture>
                        <source srcSet={camadaxy.srcWebp} type="image/webp" />
                        <source
                          srcSet={camadaxy.src}
                          type={`image/${camadaxy.type}`}
                        />
                        <img src={camadaxy.src} alt={camadaxy.alt} />
                      </picture>
                    )}
                  </motion.div>
                </AnimatePresence>
              );
            })}
          </motion.section>
        </AnimatePresence>
      )}
      {!ehMobile && (
        <AnimatePresence>
          <motion.div
            className="conteudo"
            variants={homeConteudoAnim}
            initial="iniciar"
            animate="animar"
            exit="sair"
          >
            <div className="home3">
              <div className="container">
                <Conteudo />
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      )}
      {ehMobile && (
        <main className="mobile">
          <AnimatePresence>
            <motion.header
              className="topo_mob"
              variants={containerVari}
              initial="iniciar"
              animate="animar"
            >
              {topo_camada_mob.map((camadaxy, index) => {
                const camadaVari = {
                  iniciar: {
                    x: `${camadaxy.x}vw`,
                    y: `${camadaxy.y}vh`,
                    opacity: camadaxy.opacA,
                  },
                  animar: {
                    x: 0,
                    y: 0,
                    opacity: camadaxy.opacZ,
                    transition: {
                      type: "Inertia",
                      duration: 1,
                    },
                  },
                };
                return (
                  <AnimatePresence key={camadaxy.id * Math.random()}>
                    <motion.div
                      className={`camada_mob_${index + 1} camada_mob`}
                      variants={camadaVari}
                      style={{ zIndex: 100 + index }}
                    >
                      {camadaxy.src && camadaxy.srcWebp && (
                        <picture>
                          <source srcSet={camadaxy.srcWebp} type="image/webp" />
                          <source
                            srcSet={camadaxy.src}
                            type={`image/${camadaxy.type}`}
                          />
                          <img src={camadaxy.src} alt={camadaxy.alt} />
                        </picture>
                      )}
                    </motion.div>
                  </AnimatePresence>
                );
              })}
            </motion.header>
          </AnimatePresence>
          <AnimatePresence>
            <motion.main
              className="conteudo_mob"
              variants={homeConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <div>
                <Conteudo />
              </div>
            </motion.main>
          </AnimatePresence>
          <AnimatePresence>
            <motion.footer
              className="rodape_mob"
              variants={homeConteudoAnim}
              initial="iniciar"
              animate="animar"
              exit="sair"
            >
              <picture>
                <source srcSet={rodape_camada_mob.srcWebp} type="image/webp" />
                <source
                  srcSet={rodape_camada_mob.src}
                  type={`image/${rodape_camada_mob.type}`}
                />
                <img src={rodape_camada_mob.src} alt={rodape_camada_mob.alt} />
              </picture>
            </motion.footer>
          </AnimatePresence>
        </main>
      )}
    </>
  );
}
