const containerVari = {
  iniciar: {
    opacity: 0,
    scale: 0,
    transition: {
      when: "afterChildren",
    },
  },
  animar: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.05,
      staggerChildren: 0.05,
      duration: 0.01,
      when: "beforeChildren",
    },
  },
  sair: {
    opacity: 0,
  },
};

export default containerVari;

export const naturezadadosmob = [
  {
    id: 1,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/natureza/mob/natureza_1.png",
    srcWebp: "./img/natureza/mob/natureza_1.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 2,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/mob/assinatura.png",
    srcWebp: "./img/home/0/mob/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/natureza/mob/natureza_2.jpg",
    srcWebp: "./img/natureza/mob/natureza_2.webp",
    alt: "Textura plantas",
    type: "jpeg",
    classe: "natureza",
    titulo: "Plantas",
    modal: ".",
  },
  {
    id: 4,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/natureza/mob/natureza_3.jpg",
    srcWebp: "./img/natureza/mob/natureza_3.webp",
    alt: "Textura de lugares",
    type: "jpeg",
    classe: "natureza",
    titulo: "Lugares na natureza",
    modal: ".",
  },
  {
    id: 5,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_3.png",
    srcWebp: "./img/home/1/mob/camada_3.webp",
    alt: "Textura de rodapé",
    type: "png",
  },
];

export const omardadosmob = [
  {
    id: 1,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/omar/mob/mar_1.png",
    srcWebp: "./img/omar/mob/mar_1.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 2,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/mob/assinatura.png",
    srcWebp: "./img/home/0/mob/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/omar/mob/mar_2.jpg",
    srcWebp: "./img/omar/mob/mar_2.webp",
    alt: "Textura de O mar",
    type: "jpeg",
    classe: "omar",
    titulo: "Fascínio pelo mar",
    modal: ".",
  },
  {
    id: 4,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/omar/mob/mar_3.jpg",
    srcWebp: "./img/omar/mob/mar_3.webp",
    alt: "Textura de O mar",
    type: "jpeg",
    classe: "omar",
    titulo: "O cotidiano à beira mar",
    modal: ".",
  },
  {
    id: 5,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/omar/mob/mar_4.jpg",
    srcWebp: "./img/omar/mob/mar_4.webp",
    alt: "Textura de O mar",
    type: "jpeg",
    classe: "omar",
    titulo: "Miudezas",
    modal: ".",
  },
  {
    id: 6,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_3.png",
    srcWebp: "./img/home/1/mob/camada_3.webp",
    alt: "Textura de rodapé",
    type: "png",
  },
];

export const mariodadosmob = [
  {
    id: 1,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/mario/mob/mario_1.png",
    srcWebp: "./img/mario/mob/mario_1.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 2,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/mob/assinatura.png",
    srcWebp: "./img/home/0/mob/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/mario/mob/mario_2.jpg",
    srcWebp: "./img/mario/mob/mario_2.webp",
    alt: "Textura autorretrato Mario Bhering",
    type: "jpeg",
    classe: "mariobhering",
    titulo: "Autorretrato",
    modal: ".",
  },
  {
    id: 4,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_3.png",
    srcWebp: "./img/home/1/mob/camada_3.webp",
    alt: "Textura de rodapé",
    type: "png",
  },
];

export const pessoasdadosmob = [
  {
    id: 1,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/pessoas/mob/pessoas_1.png",
    srcWebp: "./img/pessoas/mob/pessoas_1.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 2,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/mob/assinatura.png",
    srcWebp: "./img/home/0/mob/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/pessoas/mob/pessoas_3.jpg",
    srcWebp: "./img/pessoas/mob/pessoas_3.webp",
    alt: "Textura com rosto feminino",
    type: "jpeg",
    classe: "pessoas",
    titulo: "",
    modal: ".",
  },
  {
    id: 4,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_3.png",
    srcWebp: "./img/home/1/mob/camada_3.webp",
    alt: "Textura de rodapé",
    type: "png",
  },
];

export const usinasdadosmob = [
  {
    id: 1,
    x: 0,
    y: -100,
    opacA: 0,
    opacZ: 1,
    src: "./img/usinas/mob/usina_1.png",
    srcWebp: "./img/usinas/mob/usina_1.webp",
    alt: "Textura topo",
    type: "png",
  },
  {
    id: 2,
    x: -100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/0/mob/assinatura.png",
    srcWebp: "./img/home/0/mob/assinatura.webp",
    alt: "Assinatura de Mario Bhering",
    type: "png",
  },
  {
    id: 3,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/usinas/mob/usina_3.jpg",
    srcWebp: "./img/usinas/mob/usina_3.webp",
    alt: "Textura com desenho de uma usina",
    type: "jpeg",
  },
  {
    id: 4,
    x: 100,
    y: 0,
    opacA: 0,
    opacZ: 1,
    src: "./img/home/1/mob/camada_3.png",
    srcWebp: "./img/home/1/mob/camada_3.webp",
    alt: "Textura de rodapé",
    type: "png",
  },
];
