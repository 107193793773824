import { useNavigate } from "react-router-dom";
import YouTube, { YouTubeProps } from "react-youtube";
// estilo
import "estilo/componentes/intro_video.css";

export default function IntroVideo() {
  const irPara = useNavigate();
  const videoOpcoes: YouTubeProps["opts"] = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
      loop: 0,
    },
  };

  const videoFinal: YouTubeProps["onEnd"] = (event) => {
    event.target.pauseVideo();
    irPara("/home/1");
  };
  return (
    <>
      <div className="intro_video">
        <div className="modal_video">
          <YouTube
            videoId="UBL42hX3_iA"
            opts={videoOpcoes}
            onEnd={videoFinal}
          />
        </div>
      </div>
    </>
  );
}
